import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Button, Box, Typography } from '@mui/material';
import useApiManager from 'networking/ApiManager';
import authAPI from 'networking/AuthAxios';
import { toast } from 'react-toastify';

const RecordPaymentForm = ({
  onSubmit,
  recordPaymentPayload,
  closeDrawers,
  setInvalidateAPIKey,
  setCurrentSelectedPlan,
}) => {
  const axios = authAPI;
  const ApiClient = useApiManager();
  const [EEList, setEEList] = useState([]);
  const [formValues, setFormValues] = useState({
    mode: '',
    form: '',
    amount: recordPaymentPayload?.amount,
    paymentWith: '',
    notes: '',
  });

  useEffect(() => {
    setFormValues({
      mode: '',
      form: '',
      amount: recordPaymentPayload?.amount || '',
      paymentWith: '',
      notes: '',
    });
  }, [recordPaymentPayload]);
  const paymentFormOffline = [{ title: 'Cash', id: 'cash' }];
  const modeOptions = [
    { label: 'Online', value: 'online' },
    { label: 'Offline', value: 'offline' },
  ];
  const isOnline = formValues.mode === 'online';
  const formOptions = isOnline
    ? [
        { label: 'UPI', value: 'upi' },
        { label: 'Card', value: 'card' },
        { label: 'Net Banking', value: 'net_banking' },
        { label: 'Save In', value: 'savein' },
        { label: 'Snapmint', value: 'snapmint' },
      ]
    : [{ label: 'Cash', value: 'cash' }];

  const paymentWithOptions = [
    { label: 'Bank', value: 'bank' },
    { label: 'Wallet', value: 'wallet' },
    { label: 'Check', value: 'check' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const cashPaymentPayload = recordPaymentPayload;
    ApiClient.createPlanPaymentRequest(cashPaymentPayload)
      .then((res) => {
        console.log('createPlanPaymentRequest', res);
        const paymentReqId = res.data.id;
        const paymentRecordPayload = {
          amount: formValues.amount,
          payment_mode: formValues.mode,
          payment_form: formValues.form,
          operator: formValues.paymentWith,
          payment_request_id: paymentReqId,
          notes: formValues.notes,
          admin_notes: recordPaymentPayload.admin_notes,
          user_notes: recordPaymentPayload.user_notes,
        };
        axios
          .post('/payment/', paymentRecordPayload)
          .then((res) => {
            console.log('createPaymentRecord', res);
            toast.success('Payment Recorded Successfully');
            setInvalidateAPIKey((p) => p + 1);
            setCurrentSelectedPlan();
            closeDrawers();
          })
          .catch((err) => {
            console.log('createPaymentRecord error', err);
            const errData = err.response.data;
            console.log('errData', errData);
            Object.values(errData).map((errMsg) => toast.error(errMsg[0]));
          });
      })
      .catch((err) => {
        console.log('createPlanPaymentRequest error', err);
        const errData = err.response.data;
        console.log('errData', errData);
        Object.values(errData).map((errMsg) => toast.error(errMsg[0]));
      });
  };
  useEffect(() => {
    axios
      .get('/admin_profiles/return_operator_list?role=ee')
      .then((res) => {
        setEEList(res.data);
      })
      .catch((err) => {
        console.log('Failed to get EE list', err);
      });
  }, []);
  return (
    <Box
      id="modal1-form"
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        mx: 'auto',
        p: 2,
      }}
    >
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        Record Payment
      </Typography>

      <TextField
        label="Mode"
        name="mode"
        value={formValues.mode}
        onChange={handleInputChange}
        select
        fullWidth
        margin="normal"
        variant="outlined"
        required
      >
        {modeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Form"
        name="form"
        value={formValues.form}
        onChange={handleInputChange}
        select
        fullWidth
        margin="normal"
        variant="outlined"
        required
      >
        {formOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Amount"
        name="amount"
        value={formValues.amount || ''}
        onChange={handleInputChange}
        type="number"
        fullWidth
        margin="normal"
        // variant="outlined"
        disabled
      />

      <TextField
        label="Payment With ElevateNow"
        name="paymentWith"
        value={formValues.paymentWith}
        onChange={handleInputChange}
        select
        fullWidth
        margin="normal"
        variant="outlined"
        required
      >
        {EEList?.map((option) => (
          <MenuItem key={option.full_name} value={option.id}>
            {option.full_name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Notes"
        name="notes"
        value={formValues.notes}
        onChange={handleInputChange}
        multiline
        rows={4}
        fullWidth
        margin="normal"
        variant="outlined"
      />

      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Record Payment
      </Button>
    </Box>
  );
};

export default RecordPaymentForm;
