import React, { useCallback, useState } from "react";
import "../components/sales/drawer.scss"; // Create this for custom styles
import AddPlanForm from "whealth-core-web/forms/AddPlanForm";
import CloseIcon from "@mui/icons-material/Close";
import PausePlanForm from "scenes/Plans2Temp/PausePlanForm";
import ExtendPlanForm from "scenes/Plans2Temp/ExtendPlanForm";

function ExtendPlanDrawer({
  isOpen,
  setIsOpen,
  setInvalidateAPIKey,
  planData,
}) {
  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <div className="drawerContainer">
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <button
          onClick={toggleDrawer}
          // className="closeDrawer"
          style={{ position: "absolute", right: 20, top: 20, zIndex: 1000 }}
        >
          <CloseIcon />
        </button>
        <ExtendPlanForm
          planData={planData}
          toggleDrawer={toggleDrawer}
          setInvalidateAPIKey={setInvalidateAPIKey}
        />
      </div>
      <div
        className={`overlay ${isOpen ? "active" : ""}`}
        onClick={toggleDrawer}
      ></div>
    </div>
  );
}

export default ExtendPlanDrawer;
