import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import { filters, orderDetails, orderDetailsBlue, patientDetails, search, viewOrder, viewOrderBlue } from 'res/images';
import { Dropdown, Modal } from 'rsuite';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, exportToCsv, timeFormat, titlizeString } from '../Helper';
import Loader from '../Loader';
import './Orders.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PatientDetails from './PatientDetails';
import OrderDetails from './OrderDetails';
import ViewOrder from './ViewOrder';
import { useSelector, useDispatch } from 'react-redux';
import {
  useCreateAnOrderMutation,
  useGetPrescriptionListQuery,
  useUpdateManualOrderMutation,
} from 'redux/dashboardApis';
import { setManualOrderId, setManualOrderIdStr, setFromViewOrderToOrderDetail } from 'redux/Slices';
import { downloadIcon } from 'whealth-core-web/assets/images';

function AddOrder() {
  const dispatch = useDispatch();
  const patientAddress = useSelector((state) => state?.sliceReducer?.patientAddress);
  const patientManualDetails = useSelector((state) => state?.sliceReducer?.patientManualDetails);
  const manualOrderItems = useSelector((state) => state?.sliceReducer?.manualOrderItems);
  const manualOrderSummary = useSelector((state) => state?.sliceReducer?.manualOrderSummary);
  const reduxNotesData = useSelector((state) => state?.sliceReducer?.reduxNotesData);
  const latestPrescription = useSelector((state) => state?.sliceReducer?.latestPrescription);
  const { orderId: urlOrderId, orderStatus: urlOrderStatus, patientId: urlPatientId, index } = useParams();
  const location = useLocation();
  const { orderStatus, patientName, orderId } = location.state || {};
  const [publishedPrescription, setPublishedPrescription] = useState();
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const [isNewMedicineAdded, setIsNewMedicineAdded] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [error, setError] = useState('');
  const [medicineErrors, setMedicineErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [newOrderId, setNewOrderId] = useState();
  const [currentOrderStatus, setCurrentOrderStatus] = useState('');
  const PatientAddressStep1 = useSelector((state) => state?.sliceReducer?.patientAddressStep1);
  const manualOrderId = useSelector((state) => state?.sliceReducer?.manualOrderId);
  let [createManualOrder, manualOrderResponseData] = useCreateAnOrderMutation();
  let [updateManualOrder, updateManualOrderResponseData] = useUpdateManualOrderMutation();
  const value = useRef('');
  const [activeTab, setActiveTab] = useState(orderStatus === 'draft' ? 'viewOrder' : 'patientDetails');

  const getPrescription = (id) => {
    ApiClient.prescriptionList(id)
      .then((res) => {
        const prescription = res?.data?.data?.find((item) => item.status === 'published');
        setPublishedPrescription(prescription);
      })
      .catch((err) => {
        console.error('Error fetching prescription: ', err);
      });
  };

  useEffect(() => {
    if (patientManualDetails?.id) {
      getPrescription(patientManualDetails?.id);
    }
  }, [patientManualDetails]);

  const checkManualOrderItems = (manualOrderItemsArray) => {
    if (manualOrderItemsArray.length === 0) {
      setMedicineErrors('Please select a variation for all medicines');
      return false;
    }
    for (let i = 0; i < manualOrderItemsArray.length; i++) {
      const item = manualOrderItemsArray[i];
      if (!item.selectedVariation && Number(item.mrp) > 0) {
        if (manualOrderItems.length == 1) {
          setMedicineErrors('Please select a variation for selected medicine');
        } else {
          setMedicineErrors('Please select a variation for all medicines');
        }
        return false;
      }
    }
    setMedicineErrors('');
    return true;
  };

  const checkAvailablePrescription = () => {
    if (!latestPrescription?.id) {
      setMedicineErrors('A prescription is needed to place an order');
      return false;
    }
    return true;
  };
  // const prescriptionList = useGetPrescriptionListQuery({id: patientManualDetails?.id });
  // const publishedPrescription = prescriptionList?.data?.data?.find(item => item.status === 'published');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'patientDetails':
        return <PatientDetails error={error} setError={setError} handleNext={handleNext} />;
      case 'orderDetails':
        return (
          <OrderDetails
            isNewMedicineAdded={isNewMedicineAdded}
            setIsNewMedicineAdded={setIsNewMedicineAdded}
            medicineErrors={medicineErrors}
            publishedPrescription={publishedPrescription}
            orderSummaryShipping={manualOrderSummary}
          />
        );

      case 'viewOrder':
        return (
          <ViewOrder
            orderStatus={orderStatus}
            patientsName={patientName}
            ordersId={orderId || newOrderId}
            publishedPrescription={publishedPrescription}
            notesData={reduxNotesData}
          />
        );
      default:
        return <PatientDetails />;
    }
  };

  const updateOrder = (oid, data) => {
    ApiClient.updateManualOrders(oid, data)
      .then((response) => {
        setCurrentOrderStatus(response.data.ehr_order_status);
        setNewOrderId(response.data.id);
        dispatch(setManualOrderId(response.data.id));
        dispatch(setManualOrderIdStr(response.data.order_id));
        dispatch(setManualOrderId(response.data));
        setActiveTab('viewOrder');
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('Error updating order: ', err);
      });
  };

  const handleNext = async () => {
    switch (activeTab) {
      case 'patientDetails':
        dispatch(setFromViewOrderToOrderDetail(false));
        //if (patientAddress?.id && patientManualDetails?.id) {
        if (patientAddress?.id && (patientManualDetails?.id || urlPatientId)) {
          setError('');
          setActiveTab('orderDetails');
        } else if (!patientAddress?.id) {
          setError("Please provide the patient's delivery address to continue with the order");
        } else if (!patientManualDetails?.id) {
          setError('Please select a patient to proceed');
        }
        break;
      case 'orderDetails':
        if ((orderStatus === 'draft' || currentOrderStatus === 'draft') && checkManualOrderItems(manualOrderItems)) {
          const id = orderId || newOrderId;
          let manualOrderPost = {
            pincode: patientAddress.pincode,
            //source: 'Manual',
            prescription: latestPrescription?.id,
            user: patientManualDetails.id || urlPatientId,
            latest_shipping_address: patientAddress.id,
            shipping_address: '',
            order_items: manualOrderItems
              .filter((item) => parseFloat(item.mrp) > 0) // Filter out items with mrp <= 0
              .map((item) => {
                const mrp = parseFloat(item.mrp); // Ensure mrp is a number
                const discount = parseFloat(item.discount) || 0; // Ensure discount is a number

                return {
                  product_item_id: item.medicineId ? item.medicineId : item.id,
                  quantity: item.quantity,
                  item_amount: (mrp - (mrp * discount) / 100)?.toFixed(2),
                  item_mrp: mrp.toFixed(2),
                  fulfilled_qty: item.quantity,
                  discount_percentage: discount,
                };
              }),
            total_amount: parseFloat(manualOrderSummary?.total?.toFixed(2)),
            shipping_amount: parseFloat(manualOrderSummary?.shipping),
            payment_link: null,
            paid_amount: 0,
            discounted_amount: parseFloat((0).toFixed(2)),
          };
          //updateOrder(id, manualOrderPost)
          let response = await updateManualOrder({
            id: id,
            data: manualOrderPost,
          }).unwrap();
          if (response.status == 200 && response.data.id) {
            setCurrentOrderStatus(response.data.ehr_order_status);
            setNewOrderId(response.data.id);
            dispatch(setManualOrderId(response.data.id));
            dispatch(setManualOrderIdStr(response.data.order_id));
            dispatch(setManualOrderId(response.data));
            setActiveTab('viewOrder');
          }
          setActiveTab('viewOrder');
        } else if (
          manualOrderItems.length > 0 &&
          checkManualOrderItems(manualOrderItems) &&
          checkAvailablePrescription()
        ) {
          let manualOrderPost = {
            pincode: patientAddress.pincode,
            source: 'Manual',
            prescription: latestPrescription?.id,
            user: patientManualDetails.id || urlPatientId,
            latest_shipping_address: patientAddress.id,
            shipping_address: '',
            order_items: manualOrderItems
              .filter((item) => parseFloat(item.mrp) > 0) // Filter out items with mrp <= 0
              .map((item) => {
                const mrp = parseFloat(item.mrp); // Ensure mrp is a number
                const discount = parseFloat(item.discount) || 0; // Ensure discount is a number

                return {
                  product_item_id: item.medicineId ? item.medicineId : item.id,
                  quantity: item.quantity,
                  item_amount: (mrp - (mrp * discount) / 100)?.toFixed(2),
                  item_mrp: mrp.toFixed(2),
                  fulfilled_qty: item.quantity,
                  discount_percentage: discount,
                };
              }),
            total_amount: parseFloat(manualOrderSummary?.total?.toFixed(2)),
            shipping_amount: manualOrderSummary?.shipping,
            payment_link: null,
            paid_amount: 0,
            discounted_amount: 0,
          };
          let response = await createManualOrder(manualOrderPost).unwrap();
          if (response.status == 200 && response.data.id) {
            setCurrentOrderStatus(response.data.ehr_order_status);
            setNewOrderId(response.data.id);
            dispatch(setManualOrderId(response.data.id));
            dispatch(setManualOrderIdStr(response.data.order_id));
            dispatch(setManualOrderId(response.data));
            setActiveTab('viewOrder');
          }
        }
        break;
      default:
        break;
    }
  };

  const handlePrevious = () => {
    switch (activeTab) {
      case 'viewOrder':
        dispatch(setFromViewOrderToOrderDetail(true));
        // if (window.confirm('You may lose manually added medicine while going back. Do you wish to continue?')) {
        //   setActiveTab('orderDetails');
        // }
        setActiveTab('orderDetails');
        break;
      case 'orderDetails':
        dispatch(setFromViewOrderToOrderDetail(false));
        setActiveTab('patientDetails');
        break;
      default:
        dispatch(setFromViewOrderToOrderDetail(false));
        break;
    }
  };

  useEffect(() => {
    if (!openDetailModal) searchOrders();
  }, [searchStr, currentPage, openDetailModal]);

  const searchOrders = () => {
    setIsLoading(true);
    let params = { page: currentPage, search_str: searchStr };
    //let params = { page: orderCurentPage === 0 ? currentPage : orderCurentPage, search_str: searchStr };
    ApiClient.searchOrders(params)
      .then((res) => {
        setIsLoading(false);
        if (value.current === params.search_str) {
          setTotalPages(res.headers['total-pages']);
          setOrderList(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('getOrders', err.response);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className='w-100'>
        <CoreInputBox
          placeholder='Search a Order by Patient name or Order ID'
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const downloadOrder = () => {
    const id = typeof manualOrderId === 'object' ? manualOrderId.id : manualOrderId;
    const ordId = id ? id : urlOrderId;
    ApiClient.downloadOrder(ordId, { responseType: 'arraybuffer' })
    .then((res) => {
      const imageUrl = res.data.image_url;
      fetch(imageUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = `order_${ordId}.png`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
      // Open image in a new tab
      const newTab = window.open(imageUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open image in a new tab.");
      }
    })
    .catch((err) => {
      console.log("downloadOrder error: ", err);
    });
  };

  const renderHeader = () => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='shopping_cart' title='Orders' searchBox={renderSearchBox()} />
      </div>
    );
  };

  const showOrders = () => {
    const textColor1 =
      activeTab == 'patientDetails' || activeTab == 'orderDetails' || activeTab == 'viewOrder' ? '#243b86' : '';
    const textColor2 = activeTab == 'orderDetails' || activeTab == 'viewOrder' ? '#243b86' : '';
    const textColor3 = activeTab == 'viewOrder' ? '#243b86' : '';
    return (
      <>
        <div className='articleHeader stickyHeader'>
          {renderHeader()}
          <div className='hrLine mb-3' />
          {/* <RowLinks data={headerData} /> */}
        </div>

        <div className='px-4 wrapperContent order-form'>
          <div className='tabs'>
            <div className={`tab ${activeTab === 'patientDetails' ? 'active' : ''}`} style={{ color: textColor1 }}>
              <img className='img-icons' src={patientDetails} alt='patientDetails' />
              Patient Details
            </div>
            <div className={`tab ${activeTab === 'orderDetails' ? 'active' : ''}`} style={{ color: textColor2 }}>
              {activeTab == 'orderDetails' || activeTab == 'viewOrder' ? (
                <img className='img-icons' src={orderDetailsBlue} alt='orderDetails active' />
              ) : (
                <img className='img-icons' src={orderDetails} alt='orderDetails' />
              )}
              Order Details
            </div>
            <div className={`tab ${activeTab === 'viewOrder' ? 'active' : ''}`} style={{ color: textColor3 }}>
              {activeTab == 'viewOrder' ? (
                <img className='img-icons' src={viewOrderBlue} alt='viewOrder active' />
              ) : (
                <img className='img-icons' src={viewOrder} alt='viewOrder' />
              )}
              View Order
            </div>
          </div>
          <div className='tab-content'>{renderTabContent()}</div>

          <div className='hrLine mb-3 mt-3' />
          <div className='form-footer'>
            {/* {activeTab !== "patientDetails" && isNewMedicineAdded && (
              <button
                className="save-order-btn"
                onClick={() => navigate("/orders")}
              >
                Save Order
              </button>
            )} */}
            {activeTab === 'patientDetails' && (
              <button className='next-btn' onClick={handleNext}>
                Next
              </button>
            )}
            {activeTab === 'orderDetails' && (
              <>
                <button className='previous-btn' onClick={handlePrevious}>
                  Previous
                </button>
                <button className='next-btn' onClick={handleNext}>
                  Next
                </button>
              </>
            )}
            {activeTab === 'viewOrder' && (
              <>
                <button className='previous-btn' onClick={handlePrevious}>
                  Previous
                </button>
                <button
                  style={{
                    backgroundColor: '#fff',
                    color: '#243B86',
                    border: '1px solid #243B86',
                    borderRadius: '8px',
                    padding: '10px',
                    marginRight: '10px',
                    fontWeight: '600',
                  }}
                  onClick={downloadOrder}
                >
                  <img style={{ paddingRight: '10px' }} src={downloadIcon} alt='Download Icon' />
                  Download
                </button>
                <button
                  className='save-order-btn'
                  style={{
                    background: '#243B86',
                    color: '#FFFFFF',
                    borderRadius: '8px',
                    padding: '10px',
                    fontWeight: '600',
                  }}
                  onClick={() => navigate('/orders')}
                >
                  Save Order
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='wrapper'>
      <Loader show={isLoading} />
      {showOrders()}
    </div>
  );
}

export default AddOrder;
