import React, { useEffect, useRef, useState } from 'react';
import { closeIcon } from 'res/images';
import { Button, Checkbox, DatePicker, Form, SelectPicker } from 'rsuite';
import CoreInputBox from '../CoreInputBox';
import { useDispatch } from 'react-redux';
import useApiManager from 'networking/ApiManager';
import { setAlerts } from 'redux/Slices';
import { convertToAmPm, formatDate } from '../Helper';

function CallManagementModal({ patientId, callTypeName, setIsLoading, getPatientData }) {
  const platforms = ['Whatsapp', 'Phone Call', 'In-Clinic', 'Others'].map((item) => ({
    label: item,
    value: item,
  }));
  const durations = Array.from({ length: 12 }, (_, i) => {
    const value = (i + 1) * 5;
    return { label: `${value} Mins`, value: `${value} Mins` };
  });
  const [formData, setFormData] = useState({
    showOnOtherPlatform: true,
    whichPlatform: '',
    tellUsWhere: '',
    date: '',
    time: '',
    duration: '',
    errors: { whichPlatformError: '', tellUsWhereError: '', dateError: '', timeError: '' },
  });
  const dispatch = useDispatch();
  const ApiClient = useApiManager();
  const modalRef = useRef(null);

  useEffect(() => {
    if (callTypeName) {
      setFormData({
        ...formData,
        date: callTypeName?.call_date ? new Date(callTypeName?.call_date) : '',
        time: convertTimeToDate(callTypeName?.time),
        duration: `${callTypeName?.duration} Mins`,
        whichPlatform: callTypeName?.platform || '',
        tellUsWhere: callTypeName?.other_platform || '',
      });
    }
  }, [callTypeName]);

  const handleSelect = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
      errors: {
        whichPlatformError: '',
        tellUsWhereError: '',
        dateError: '',
        timeError: '',
      },
    }));
  };

  const handleModalClose = () => {
    setFormData({
      showOnOtherPlatform: true,
      whichPlatform: '',
      tellUsWhere: '',
      date: '',
      time: '',
      duration: '',
      errors: {},
    });
  };

  const getTitle = (key) => {
    switch (key) {
      case 'hc_call':
        return 'HC Call';
      case 'onboarding_call':
        return 'Onboarding Call';
      default:
        return '';
    }
  };

  const convertTimeToDate = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(':').map(Number);
      const now = new Date();
      now.setHours(hours, minutes, 0, 0);
      return now;
    } catch (e) {
      return '';
    }
  };

  const formatDateBody = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0'); // Ensures two digits for hours
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensures two digits for minutes
    return `${hours}:${minutes}`;
  };

  const getCallNumber = () => {
    try {
      return callTypeName?.callType === 'hc_call'
        ? callTypeName?.type.split('_')[callTypeName?.type.split('_').length - 1]
        : '';
    } catch (e) {
      return '';
    }
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const handleClose = () => handleModalClose();
      modalElement.addEventListener('hidden.bs.offcanvas', handleClose);
      return () => {
        modalElement.removeEventListener('hidden.bs.offcanvas', handleClose);
      };
    }
  });

  const filterHeader = (status, date, time) => {
    switch (status) {
      case 'initiated':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
            <div
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='callModalContainer'
              style={{
                backgroundColor: '#ECECEC',
                borderRadius: 15,
                padding: 1,
                alignSelf: 'flex-end',
                marginBottom: 10,
              }}
              onPress={() => {
                setFormData({ showOnOtherPlatform: true, whichPlatform: '', date: '', time: '', duration: '' });
              }}
            >
              <img src={closeIcon} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: '#F1F5FF',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 10,
                width: '80%',
                alignSelf: 'center',
              }}
            >
              <p style={{ color: '#243B86', fontWeight: 600, fontSize: 16 }}>
                {/* {callTypeName} ({currentCalls}/{totalScheduledCalls}) */}
                {getTitle(callTypeName?.callType)} {getCallNumber()}
              </p>
              <div
                style={{
                  backgroundColor: '#FFE897',
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 15,
                  marginTop: 5,
                }}
              >
                <p style={{ color: '#945900', fontWeight: 500, fontSize: 12 }}>Pending</p>
              </div>
            </div>
          </div>
        );
      case 'cancelled':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
            <div
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='callModalContainer'
              style={{
                backgroundColor: '#ECECEC',
                borderRadius: 15,
                padding: 1,
                alignSelf: 'flex-end',
                marginBottom: 10,
              }}
              onPress={() => {
                setFormData({ showOnOtherPlatform: true, whichPlatform: '', date: '', time: '', duration: '' });
              }}
            >
              <img src={closeIcon} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: '#F1F5FF',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 10,
                width: '80%',
                alignSelf: 'center',
              }}
            >
              <p style={{ color: '#243B86', fontWeight: 600, fontSize: 16 }}>
                {/* {callTypeName} ({currentCalls} /{totalScheduledCalls}) */}
                {getTitle(callTypeName?.callType)} {getCallNumber()}
              </p>
              <div
                style={{
                  backgroundColor: '#DC2626',
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 15,
                  marginTop: 5,
                }}
              >
                <p style={{ color: 'white', fontWeight: 500, fontSize: 12 }}>Cancelled</p>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '100%' }}>
            <div
              data-bs-dismiss='offcanvas'
              aria-label='Close'
              id='callModalContainer'
              style={{
                backgroundColor: '#ECECEC',
                borderRadius: 15,
                padding: 1,
                alignSelf: 'flex-end',
                marginBottom: 10,
              }}
              onPress={() => {
                setFormData({ showOnOtherPlatform: true, whichPlatform: '', date: '', time: '', duration: '' });
              }}
            >
              <img src={closeIcon} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: '#F1F5FF',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 10,
                width: '80%',
                alignSelf: 'center',
              }}
            >
              <p style={{ color: '#243B86', fontWeight: 600, fontSize: 16 }}>
                {/* {callTypeName} ({currentCalls}/{totalScheduledCalls}) */}
                {getTitle(callTypeName?.callType)} {getCallNumber()}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  colr: '#333333',
                  fontSize: 14,
                  marginTop: 5,
                }}
              >
                <p>{date && formatDate(date)}</p>
                <div style={{ width: 1, marginRight: 5, marginLeft: 5, height: 15, backgroundColor: '#333333' }}></div>
                <p>{convertToAmPm(time)}</p>
              </div>
            </div>
          </div>
        );
    }
  };

  const extractErrorMessages = (errors) => {
    if (!errors || typeof errors !== 'object') return '';
    let concatenatedErrors = '';

    for (const key in errors) {
      if (Array.isArray(errors[key]) && errors[key][0]) {
        concatenatedErrors += errors[key][0] + ' ';
      }
    }
    return concatenatedErrors.trim();
  };

  const handleSubmit = () => {
    let errors = {
      whichPlatformError: '',
      tellUsWhereError: '',
      dateError: '',
      timeError: '',
    };

    if (formData.whichPlatform.trim() === '') {
      errors.whichPlatformError = 'This Field is Required';
    }
    if (formData.whichPlatform === 'Others' && formData.tellUsWhere.trim() === '') {
      errors.tellUsWhereError = 'This Field is Required';
    }
    if (formData.date === '') {
      errors.dateError = 'This Field is Required';
    }
    if (formData.time === '') {
      errors.timeError = 'Thi Field is Required';
    }

    setFormData((prevData) => ({
      ...prevData,
      errors,
    }));

    let errorCondition = formData.showOnOtherPlatform
      ? !errors.whichPlatformError && !errors.tellUsWhereError && !errors.dateError && !errors.timeError
      : !errors.dateError && !errors.timeError;

    if (errorCondition) {
      setIsLoading(true);
      let modalDiv = document.getElementById('callModalContainer');

      let params = formData.showOnOtherPlatform
        ? {
            type: callTypeName?.callType,
            status: 'completed',
            user: callTypeName?.user?.id,
            call_date: formatDateBody(formData.date),
            time: formatTime(formData.time),
            duration: parseInt(formData.duration, 10),
            platform: formData.whichPlatform,
            other_platform: formData.whichPlatform === 'Others' ? formData.tellUsWhere : '',
            meeting_id: '',
          }
        : {
            type: callTypeName?.callType,
            status: 'no_show',
            user: callTypeName?.user?.id,
            meeting_id: '',
            call_date: callTypeName?.call_date,
            time: callTypeName?.time,
            duration: callTypeName?.duration,
            platform: callTypeName?.platform,
            other_platform: callTypeName?.other_platform,
          };

      ApiClient.userCallLogsUpdate(patientId, params)
        .then((res) => {
          setIsLoading(false);
          dispatch(setAlerts({ patient: { title: 'Status Updated Successfully.', type: 'alert-success' } }));
          getPatientData();
        })
        .catch((err) => {
          setIsLoading(false);
          dispatch(
            setAlerts({ patient: { title: extractErrorMessages(err?.response?.data?.errors), type: 'alert-danger' } })
          );
          getPatientData();
        })
        .finally(() => {
          if (modalDiv) {
            modalDiv.click();
          }
        });
    }
  };

  const renderFilters = () => {
    return (
      <Form style={{ width: '80%', margin: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Checkbox
            style={{
              '--rs-checkbox-checked-bg': '#243B86',
              '--rs-checkbox-checked-border-color': '#243B86',
            }}
            checked={formData.showOnOtherPlatform}
            onChange={() => handleSelect('showOnOtherPlatform', true)}
          >
            Show on other platform
          </Checkbox>
          <Checkbox
            style={{
              '--rs-checkbox-checked-bg': '#243B86',
              '--rs-checkbox-checked-border-color': '#243B86',
            }}
            checked={!formData.showOnOtherPlatform}
            onChange={() => handleSelect('showOnOtherPlatform', false)}
          >
            No Show
          </Checkbox>
        </div>

        {formData.showOnOtherPlatform && (
          <Form.Group className='mb-3 mt-3'>
            <Form.ControlLabel>
              Which Platform did the call happen on?<span style={{ color: 'red' }}>*</span>
            </Form.ControlLabel>
            <div id='selectPickerContainer' className='position-relative'>
              <SelectPicker
                data={platforms}
                searchable={false}
                block
                container={() => document.getElementById('selectPickerContainer')}
                onSelect={(value) => handleSelect('whichPlatform', value)}
                onClean={() => handleSelect('whichPlatform', '')}
                value={formData.whichPlatform}
              />
            </div>
            <div>
              {formData.errors.whichPlatformError && (
                <p style={{ color: 'red', padding: 5 }}>{formData.errors.whichPlatformError}</p>
              )}
            </div>
          </Form.Group>
        )}

        {formData.whichPlatform === 'Others' && formData.showOnOtherPlatform && (
          <Form.Group className='mb-3 mt-3'>
            <Form.ControlLabel>
              Please tell us where?<span style={{ color: 'red' }}>*</span>
            </Form.ControlLabel>
            <CoreInputBox
              inputStyle={{ height: 37 }}
              value={formData.tellUsWhere}
              setValue={(value) => handleSelect('tellUsWhere', value)}
              placeholder='Please tell us where'
            />
            {formData.errors.tellUsWhereError && (
              <p style={{ color: 'red', padding: 5 }}>{formData.errors.tellUsWhereError}</p>
            )}
          </Form.Group>
        )}

        <div style={{ marginBottom: 20 }}>
          <Form.ControlLabel>
            {formData.showOnOtherPlatform ? 'Confirm Date' : 'Date'}
            <span style={{ color: 'red' }}>*</span>
          </Form.ControlLabel>
          <div id='datePickerContainer' className='position-relative'>
            <DatePicker
              format='dd MMM yyyy'
              placeholder='Select Date'
              container={() => document.getElementById('datePickerContainer')}
              onSelect={(date) => handleSelect('date', date)}
              onClean={() => handleSelect('date', '')}
              style={{ width: '100%' }}
              value={
                formData.showOnOtherPlatform
                  ? formData.date || null
                  : callTypeName?.call_date && new Date(callTypeName?.call_date)
              }
              disabled={true}
            />
          </div>
          {formData.errors.dateError && <p style={{ color: 'red', padding: 5 }}>{formData.errors.dateError}</p>}
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
          <div style={{ width: '50%', marginRight: 10 }}>
            <Form.ControlLabel>
              Time<span style={{ color: 'red' }}>*</span>
            </Form.ControlLabel>
            <div id='timePickerContainer' className='position-relative'>
              <DatePicker
                container={() => document.getElementById('timePickerContainer')}
                format='hh:mm aa'
                showMeridian
                placeholder='Select Time'
                onClean={() => handleSelect('time', '')}
                onSelect={(time) => {
                  handleSelect('time', time);
                }}
                value={formData.showOnOtherPlatform ? formData.time || null : convertTimeToDate(callTypeName?.time)}
                disabled={true}
              />
            </div>
            {formData.errors.timeError && <p style={{ color: 'red', padding: 5 }}>{formData.errors.timeError}</p>}
          </div>
          <div style={{ width: '50%' }}>
            <Form.ControlLabel>Duration</Form.ControlLabel>
            <div id='durationPickerContainer' className='position-relative'>
              <SelectPicker
                data={durations}
                container={() => document.getElementById('durationPickerContainer')}
                onSelect={(duration) => handleSelect('duration', duration)}
                onClean={() => handleSelect('duration', '')}
                style={{ width: '100%' }}
                value={formData.showOnOtherPlatform ? formData.duration : `${callTypeName?.duration} Mins`}
                disabled={!formData.showOnOtherPlatform}
              />
            </div>
          </div>
        </div>

        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <Button style={{ width: '70%', backgroundColor: '#243B86', color: 'white' }} onClick={handleSubmit}>
            Update Details
          </Button>
        </div>
      </Form>
    );
  };

  const renderModal = () => {
    return (
      <div className='filterModal'>
        <div
          className='offcanvas offcanvas-end'
          tabIndex='-2'
          id='callModal'
          aria-labelledby='callModalLabel'
          ref={modalRef}
        >
          <div className='whiteBackground' style={{ width: '550px' }}>
            {filterHeader(callTypeName?.status, callTypeName?.call_date, callTypeName?.time)}
            <div className='filterHeaderData'>{renderFilters()}</div>
            {/* {renderFooter()} */}
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default CallManagementModal;
