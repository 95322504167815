import React, { useState } from 'react';

const PlanItems = ({ record }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="text-sm">
      {(showAll ? record?.plan_items : record?.plan_items?.slice(0, 2))?.map((service, index) => (
        <div key={index}>
          {service.product.product_name} ({service.quantity})
        </div>
      ))}
      {record?.plan_items?.length > 2 && !showAll && (
        <div style={{ color: '#243B86', fontWeight: 500, cursor: 'pointer' }} onClick={toggleShowAll}>
          +{record.plan_items.length - 2} more
        </div>
      )}
      {showAll && (
        <div style={{ color: '#243B86', fontWeight: 500, cursor: 'pointer' }} onClick={toggleShowAll}>
          Show Less
        </div>
      )}
    </div>
  );
};

export default PlanItems;
