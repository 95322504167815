import React, { useState } from "react";
import PlanTileMini from "./PlanTileMini";
import useApiManager from "networking/ApiManager";
import { duration } from "@mui/material";
import { toast } from "react-toastify";

const initialFormData = {
  startDate: "",
  duration: "",
  reason: "",
};
const ExtendPlanForm = ({
  planData,
  patientId,
  toggleDrawer,
  setInvalidateAPIKey,
}) => {
  const planExtensionEndDates = planData?.user_plan_extension
    ?.map((el) => new Date(el.end_date))
    ?.sort((a, b) => b - a);

  const latestPlanExtensionEndDate = planExtensionEndDates?.[0];
		console.log("last extension date", latestPlanExtensionEndDate);
  const planEndDate = planData?.end_date;
  const ApiClient = useApiManager();

  const [formData, setFormData] = useState(initialFormData);
  const [formError, setFormError] = useState(false);
  const planId = planData?.id;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormError(false); // Reset error on input change
  };
  const getMinStartDate = () => {
    const extensionDate = new Date(latestPlanExtensionEndDate);
    const planEnd = new Date(planEndDate);
    let date;
    if (latestPlanExtensionEndDate) {
      date = extensionDate;
    } else {
      date = planEnd;
    }
    date.setDate(date.getDate() + 1); // 5 days back
    return date.toISOString().split("T")[0]; // Format to YYYY-MM-DD
  };

  const getMaxStartDate = () => {
    const extensionDate = new Date(latestPlanExtensionEndDate);
    const planEnd = new Date(planEndDate);
    let date;
    if (latestPlanExtensionEndDate) {
      date = extensionDate;
    } else {
      date = planEnd;
    }
    date.setDate(date.getDate() + 1); // 5 days back
    return date.toISOString().split("T")[0]; // Format to YYYY-MM-DD
  };

  const checkIfFormValuesIsEmpty = () => {
    if (
      formData.startDate === "" ||
      formData.duration === "" ||
      formData.reason === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isFormFilled = !checkIfFormValuesIsEmpty();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!formData.startDate || !formData.duration || !formData.reason) {
      setFormError(true);
      return;
    }
    const payload = {
      type: "extension",
      duration: formData.duration,
      user_plan: planId,
      start_date: formData.startDate,
      description: formData.reason,
    };
    ApiClient.pauseOrExtendPlan(patientId, payload)
      .then((res) => {
        console.log("pauseOrExtendPlan", res);
        toast.success("Plan Extended Successfully");
        setInvalidateAPIKey((p) => p + 1);
        setFormData(initialFormData);
        toggleDrawer();
      })
      .catch((err) => {
        Object.values(err.response.data).map((errMsg) =>
          toast.error(errMsg[0])
        );
        console.log("pauseOrExtendPlan error", err);
      });
    console.log("Form Submitted:", formData);
    // Add your submission logic here
  };

  return (
    <div
      className="pt-5"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p className="p-3" style={{ fontWeight: "medium", fontSize: "1rem" }}>
        Extend Plan
      </p>
      <div style={{ width: "80%" }}>
        <div className="p-3">
          <PlanTileMini planData={planData} />
        </div>
        <form onSubmit={handleSubmit} style={styles.formContainer}>
          {/* Start Date Field */}
          <label style={styles.label}>
            Start Date<span style={styles.asterisk}>*</span>
          </label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            style={styles.input}
            min={getMinStartDate()}
            max={getMaxStartDate()}
          />

          {/* Duration Field */}
          <label style={styles.label}>
            Duration<span style={styles.asterisk}>*</span>
          </label>
          <div style={{ position: "relative" }}>
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              style={styles.input}
              min="1"
            />
            <div
              style={{
                position: "absolute",
                top: "18%",
                right: "1.6rem",
                color: "#757575",
              }}
            >
              Days
            </div>
          </div>
          {/* Reason Field */}
          <label style={styles.label}>
            Reason<span style={styles.asterisk}>*</span>
          </label>
          <textarea
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            style={styles.textarea}
          />

          {/* Error Message */}
          {formError && (
            <p style={styles.errorMessage}>
              Please fill in all fields before submitting.
            </p>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            style={isFormFilled ? styles.button : styles.buttonDisabled}
            disabled={!isFormFilled}
          >
            Extend Plan
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  formContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    margin: "auto",
    padding: "20px",
  },
  label: {
    marginBottom: "5px",
    fontSize: "1rem",
  },
  asterisk: {
    color: "red",
    marginLeft: "2px",
  },
  input: {
    opacity: 1,
    height: "100%",
    width: "100%",
    position: "relative",
    marginBottom: "20px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    outline: "none", // Ensures there's no outline
    boxSizing: "border-box", // Prevents overflow due to padding/border
  },
  textarea: {
    marginBottom: "20px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    minHeight: "80px",
  },
  errorMessage: {
    color: "red",
    marginBottom: "20px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#243B86",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  buttonDisabled: {
    padding: "10px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#757575",
    border: "none",
    borderRadius: "4px",
  },
};

export default ExtendPlanForm;
