import React, { useMemo, useState } from "react";
import "./postHeader.css";

function PostHeader(props) {
  const { onClickHandle, data, postLinks, callLog } = props;
  const [activeLink, setActiveLink] = useState(
    callLog ? postLinks[1]?.link : postLinks[0]?.link || ""
  );

  const renderPostLinks = () => {
    return postLinks.map((item, index) => {
      let className = "inActive";
      if (item.link == activeLink) {
        className = "active";
      } else {
        className = "inActive";
      }
      if (postLinks.length == 1) {
        className += " text-decoration-none";
      }

      return (
        <div
          key={index}
          onClick={() => {
            onClickHandle(item);
            setActiveLink(item.link);
          }}
        >
          <div className={`${className} capitalize`}>{item.title} </div>
        </div>
      );
    });
  };

  return (
    <div className="d-flex postHeader headingTitleLink">
      {renderPostLinks()}
    </div>
  );
}

export default PostHeader;
