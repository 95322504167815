import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import auth_axios from './AuthAxios';
import axios from './Axios';
import endPoints from './endPoints';
import patientAxios from './PatientAxios';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const PATIENT_BASE_URL = 'https://dashboard.whealthstudio.com/api/v1/users/';
function useApiManager() {
  const urlParams = new URLSearchParams(window.location.search);
  const patientToken = urlParams.get('token_str');

  patientAxios.interceptors.request.use((request) => {
    request.headers['Authorization'] = `Bearer ${patientToken}`;
    return request;
  });

  const patientEndPoints = {
    GETARTICLE: (id) => `articles/${id}/`,
  };

  const endPoints = {
    DUPLICATEQUESTION: (id, question_id) => `patients/${id}/behaviour_questionnaire/${question_id}/clone/`,
    DUPLICATEDIETPLAN: (id, DietPlanID) => `patients/${id}/diet_plans/${DietPlanID}/clone/`,
    DASHBOARD: 'admins/dashboard/',
    GETCONFIG: 'admins/dashboard/config/',
    SETZOHOCREDS: 'admins/zoho_tokens/',
    GETZOHOMEETINGS: 'admins/zoho_bookings/?date=month',
    GETZOHOMEETINGSAVAILABILITY: (date) => `admins/zoho_bookings/availability/?date=${date}`,
    CREATEZOHOMEETING: 'zoho_meetings/',
    DELETEZOHOMEETING: 'zoho_meetings/',
    UPDATEZOHOMEETING: 'zoho_meetings/',
    ARTICLES: 'articles/',
    DEEPLINK: 'deep_link/',
    SEARCHDEPARTMENTS: 'department/search/',
    ARTICLECOUNT: 'articles/count/',
    SENDOTP: 'admins/send_otp/',
    VERIFYOTP: 'admins/verify_otp/',
    NOTESTEMPLATE: 'notes/template/',
    ARTICLECATEGORY: 'categories/',
    NOTESCATEGORY: 'notes/categories/',
    GETALLDEPARTSTATUSES: 'department_status/',
    SEARCHDEPARTSTATUSES: 'department_status/search/',
    ARTICLETAGS: 'tags/',
    ADMINPROFILE: (id) => (id ? `admin_profiles/${id}/` : 'admin_profiles/'),
    USERPROFILE: 'admins/profile/',
    ADMINPROFILECOUNT: 'admin_profiles/count/',
    PATIENTCOUNT: 'patients/count/',
    SEARCHTAGS: 'tags/search/',
    SEARCATEGORIES: 'categories/search/',
    SEARCHARTICLE: 'articles/search/',
    SEARCHTEAM: 'admin_profiles/search/',
    PATIENTSEARCH: 'patients/search/',
    ADMINPATIENTSEARCH: 'patients/search_admin_patients/',
    TASKSEARCH: 'admins/assigned_tasks/search/',
    CONSUTDATAGET: 'admins/consultations/',
    GETSERVICEDOCTORES: 'admin_profiles/search/?role=doctor',
    PATIENTLIST: 'patients/',
    REFRESH_TOKEN: 'token/refresh/',
    PROFILEQUESTIONS: 'profile_questions/',
    WEBENGAGE: 'web_engage/',
    FCM: 'fcm/',
    MEASURINGEVENT: 'measuring_events/',
    GOALCREATION: 'goals/',
    GOALSEARCH: 'goals/search/',
    UNASIGNADMIN: 'unassign_admin/',
    ASIGNADMIN: 'assign_admin/',
    CAREPLAN: 'care_plan/',
    MEDICINECATEGORIES: 'medicine_categories/',
    MEDICINES: 'medicines/',
    MEDICINESSEARCH: 'medicines/search/',
    MEDICINECATEGORIESEARCH: 'medicines/unique_category_search/',
    PRESCRIPTIONS: 'prescriptions/',
    BEHAVIORQUESTIONCREATE: 'behaviour_questionnaire/',
    BEHAVIORQUESTIONSEARCH: 'behaviour_questions/search/',
    BEHAVIORQUESTIONSCATEGORIES: 'behaviour_question_categories/',
    BEHAVIORQUESTIONCREATEPREVIOUS: 'behaviour_questionnaire/previous_questions/',
    AUDITLOG: 'audit_logs/',
    DASHBOARDCONFIG: 'admins/dashboard/config/',
    PATIENTPROFILETIMELINE: 'timeline/',
    NOTES: 'notes/',
    PATIENTSERVICE: 'patient_service/',
    REGIMETEMPLATE: 'behaviour_question_templates/',
    PROFILEQUESTIONSSEARCH: 'profile_questions/search/',
    BEHAVIOURQUESTION: 'behaviour_questions/',
    DIETPLAN: `diet_plan_templates/`,
    ORDERS: 'orders/',
    UPDATEORDERS: (id) => `manual_order/${id}/`,
    COUPONS: 'coupons/',
    DIETPLAN: `diet_plan_templates/`,
    DIETPLANS: `diet_plans/`,
    DIETPLANTEMPLATE: `diet_plan_templates/`,
    TASKCATEGORY: `task/categories/`,
    TASKTEMPLATE: `task/templates/`,
    PRC: 'service_category/',
    UPDATEPRC: (PRCID) => `service_category/${PRCID}/`,
    PRESCRIPTIONSCHEMA: (id, schema_id) => {
      if (schema_id) {
        return `patients/${id}/prescriptions/schema/${schema_id}/`;
      } else {
        return `patients/${id}/prescriptions/schema/`;
      }
    },
    ASSIGNTASK: 'admins/assigned_tasks/',
    TASKRESCHEDULEDATE: 'admins/reschedule_task/',
    REPORTTAB: 'reports/',
    ADHERENCE: 'adherence/',
    STATUS: 'status/',
    DAYPART: 'day_part/',
    SEGMENTDEFINITION: 'segment/',
    SEGMENTDEFINITIONSEARCH: 'segment/search',
    GETLOCATIONS: 'locations/',
    SEARCHLOCATIONS: 'locations/search/',
    DAYPARTSSEARCH: 'day_part/search/',
    DEPARTMENTS: 'department/',
    SEARCHDEPARTMENTS: 'department/search/',
    APPOINTMENTSERVICE: 'service/',
    GETAPPOINTMENT: 'appointments/',
    GETAVAILABLESLOT: 'appointments/available_slots/',
    GETDOCTORSERVICES: (id) => `admins/${id}/services/`,
    UPDATEAPPOINTMENTSERVICE: (serviceId) => `service/${serviceId}/`,
    UPDATEDEPARTMENTOBJECT: (departmentId) => `department/${departmentId}/`,
    UPDATELOCATIOB: (locationId) => `locations/${locationId}/`,
    CREATEDEPARTMENT: (patientId) => `patients/${patientId}/post_department/`,
    UPDATEDEPARTMENT: (patientId) => `patients/${patientId}/assign_department/`,
    DELETEDEPARTMENT: (patientId) => `patients/${patientId}/delete_department/`,
    GETDOCTORS: (query, id) => `admin_profiles/search_depatments_doctor/?full_name=${query}&department_id=${id}`,
    UPDATEPATIENTLOCATION: (id) => `patients/${id}/assign_location/`,
    MOBILEBANNER: 'mobile_banners/',
    PRESIGNERURL: 'presigner/',
    WEIGHTGRAPH: (id) => `patients/${id}/weight_graph/`,
    TEAMGET: (id) => `admin_profiles/${id}/`,
    // GETPATIENTMEETINGS: (id) => `patients/${id}/zoho_meetings/`,
    GETPATIENTMEETINGS: (id) => `patient/${id}/zoho_bookings/`,
    CREATEPATIENTMEETINGS: (id) => `admins/${id}/zoho_bookings/`,
    UPDATEPATIENTMEETINGS: (id) => `admins/reshedule/zoho_bookings/${id}/`,
    TASKS: (id) => `patients/${id}/tasks/`,
    TEAMWORKINGHOUR: (id) => `working_slots/?doctor_id=${id}`,
    DELETETEAMWORKINGHOUR: (id) => `working_slots/${id}/appointment_slot_delete`,
    GETALLDOCTOR: 'appointment_slots/get_doctor_appointments/',
    SLOTSCOUNT: (bookingDate, doctorId) =>
      `appointment_slots/get_time_slot/?booking_date=${bookingDate}&doctor_id=${doctorId}&location_id=all`,
    APPOINTMENTSSCHEDULE: (consult_datetime, doctor_id) =>
      `admins/doctor_appointments/?consult_datetime=${consult_datetime}&doctor_id=${doctor_id}`,
    CALLLOG: (id) => `patients/${id}/call_log/`,
    GETWEIGHTGOALID: (id) => `patients/${id}/goals/`,
    ACTIVITYLOGS: (id) => `patients/${id}/activity_logs/`,
    USERCALLLOGS: (id) => `patients/${id}/user_call_logs/`,
    ACTIVITYLOGSONCHANGE: (id) => `patients/${id}/activity_logs/latest_activity/`,
    SEAECHTASKASSIGNTOSEARCH: (id) => `patients/${id}/tasks/assign_to_search/`,
    ACTIVEPRESCTIPTION: (id) => `patients/${id}/prescriptions/active/`,
    Attatchgoal: (id) => `/goals/${id}/`,

    PATIENTGOALS: (id, goalId) => {
      if (goalId) {
        return `patients/${id}/goals/${goalId}/`;
      } else {
        return `patients/${id}/goals/`;
      }
    },

    PATIENTGOALSSEARCH: (id) => {
      if (id) {
        return `patients/${id}/goals/search/`;
      }
    },

    GETPATIENTREPORTS: (id, reportid) => {
      if (reportid) {
        return `patients/${id}/reports/${reportid}/`;
      } else {
        return `patients/${id}/reports/`;
      }
    },
    PRESCRIPTIONSLIST: (id, pid) => {
      if (pid) {
        return `patients/${id}/prescriptions/${pid}/`;
      } else {
        return `patients/${id}/prescriptions/`;
      }
    },

    DIETPLANLIST: (id, pid) => {
      if (pid) {
        return `patients/${id}/diet_plans/${pid}/`;
      } else {
        return `patients/${id}/diet_plans/`;
      }
    },

    PRESCRIBEDMEDICINE: (id) => {
      if (id) {
        return `patients/${id}/prescriptions/prescribed_medicines/`;
      }
    },

    behaviourList: (id, pid) => {
      if (pid) {
        return `patients/${id}/behaviour_questionnaire/${pid}/`;
      } else {
        return `patients/${id}/behaviour_questionnaire/`;
      }
    },

    dietPlanList: (id, pid) => {
      if (pid) {
        return `patients/${id}/diet_plan_templates/${pid}/`;
      } else {
        return `patients/${id}/diet_plan_templates/`;
      }
    },

    publishedDietPlanList: (id, pid) => {
      if (pid) {
        return `patients/${id}/diet_plans/list-published-diet-plans/${pid}`;
      } else {
        return `patients/${id}/diet_plans/list-published-diet-plans/`;
      }
    },

    dietPlanTemplateList: (id, pid) => {
      if (pid) {
        return `patients/${id}/diet_plans/${pid}/`;
      } else {
        return `patients/${id}/diet_plans/`;
      }
    },

    PRESCRIPTIONMEDICINE: (id, pid, mid) => {
      if (mid) {
        return `patients/${id}/prescriptions/${pid}/medicines/${mid}/`;
      } else {
        return `patients/${id}/prescriptions/${pid}/medicines/`;
      }
    },
    SUBSCRIPTION: (id) => `patients/${id}/subscriptions/extend/`,
    PRESCRIPTIONPDFDOWNLOAD: (id, pid) => `/patients/${id}/prescriptions/${pid}/pdf/`,
    REGIMEPDFDOWNLOAD: (id) => `/patients/${id}/behaviour_questionnaire/report_card/`,
    QUESTIONPDFDOWNLOAD: (id, pid) => `/patients/${id}/behaviour_questionnaire/${pid}/pdf/`,
    DIETPLANPDFDOWNLOAD: (id, pid) => `/patients/${id}/diet_plan_templates/${pid}/pdf/`,
    DIETPLANPDF: (id, pid) => `/patients/${id}/diet_plans/${pid}/pdf/`,
    NEWDIETPLANPDFDOWNLOAD: (id, pid) => `/patients/${id}/diet_plans/${pid}/download_pdf/`,
    PROFILEANSWERS: (id) => `/patients/${id}/profile_answers/`,
    ACTIVITYLOGACTION: (id, logId) => `/patients/${id}/activity_logs/${logId}/`,
    PRESCRIPTIONSUMMERY: (id) => `patients/${id}/prescription_summary/`,
    SUMMARYPDFDOWNLOAD: (id, summeryId) => `/patients/${id}/prescription_summary/${summeryId}/pdf/`,
    PRESCRIPTIONSUMMERYPUBLISH: (id, summeryId) => `patients/${id}/prescription_summary/${summeryId}/publish/`,
    PRESCRIPTIONSUMMERYUNPUBLISH: (id, summeryId) => `patients/${id}/prescription_summary/${summeryId}/unpublish/`,
    PRESCRIPTIONSUMMERYGETSINGLE: (id, summeryId) => `patients/${id}/prescription_summary/${summeryId}/`,
    PUBLISHEDPRESCRIPTION: (id) => `patients/${id}/prescriptions/default_published_prescription/`,
    PRODUCTLINEITEMSEARCH: `product_line_item/search/`,
    DOWNLOADORDER: (id) => `manual_order/${id}/image/`,
    GETNOTES: (id) => `generic_note?model_name=order&model_id=${id}`,
    GETMANUALORDER: (id) => `manual_order/${id}`,
    GETPATIENTADDRESS: (id) => `patients/${id}/patient_address/`,
    GETFILTEREDORDERS: () => 'orders/search/',
    GETINVENTORYMEDICINES: () => 'inventory_config/',
    GETFORMULATION: (id) => `inventory_config/get_variations/?formulation=${id}`,
    INVENTORYCONFIG: 'inventory_config/',
    INVENTORYSEARCH: `inventory_config/`,
    GETDRAFTVARIANTS: 'manual_order/draft_get_variants_line_item_order/',
    GETMEDICINECOUNT: 'inventory_config/count/',
    GETMEDICINESEARCHCOUNT: 'inventory_config/count/',
    GETALLPLANS: ({ page, searchParam, status = 'published' }) => {
      return `plan/?page=${page}&name=${searchParam}&status=${status}`;
    },
    CREATEPLAN: 'plan/',
    GETALLPLANITEMS: '/plan_items',
    UPDATEPLAN: (id) => `plan/${id}/`,
    CREATEPLANPAYMENTREQUEST: `/plan_payment_request/`,
    GETEEOPERATORLIST: '/admin_profiles/return_operator_list?role=ee',
    CREATEPAYMENTRECORD: '/payment/',

    GETALLCUSTOMERS: ({ page, searchName, searchPhone, status = '' }) => {
      return `plan_payment_request/?page=${page}&name=${searchName}&status=${status}`;
    },

    GETPLANINFOFORPATIENT: (id) => `patients/${id}/user_plan/`,
    PAUSEOREXTENDPLAN: (id) => `patients/${id}/user_plan_pause_extension/`,
  };

  // create auth apis here

  const sendOTP = async (params) => {
    return axios.post(endPoints.SENDOTP, params);
  };

  const verifyOTP = async (params) => {
    return axios.post(endPoints.VERIFYOTP, params);
  };

  // create apis here

  const getConfig = async () => {
    return auth_axios.get(endPoints.GETCONFIG);
  };

  const prescriptionSchema = async (patient_id, schema_id, params) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSCHEMA(patient_id, schema_id), {
      params,
    });
  };

  const setZohoCreds = async (params) => {
    return auth_axios.post(endPoints.SETZOHOCREDS, params);
  };

  const prescriptionSummeryCreate = async (id, params) => {
    return auth_axios.post(endPoints.PRESCRIPTIONSUMMERY(id), params);
  };

  const updateOrders = async (params) => {
    return auth_axios.post(endPoints.ORDERS, params);
  };

  const updateManualOrders = async (id, params) => {
    return auth_axios.patch(endPoints.UPDATEORDERS, params);
  };

  const getZohoMeetings = async () => {
    return auth_axios.get(endPoints.GETZOHOMEETINGS);
  };

  const getZohoMeetingsAvailability = async (date) => {
    return auth_axios.get(endPoints.GETZOHOMEETINGSAVAILABILITY(date));
  };

  const prescriptionMDTSummeryList = async (id, params) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSUMMERY(id), { params });
  };

  const getServiceCategories = async (pages) => {
    return auth_axios.get(endPoints.PRC + 'search/', { params: { ...pages } });
  };

  const patientServiceList = async (patientId, params) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/patient_service/', { params: { ...params } });
  };

  const patientServiceCreate = async (params, id, extraParam) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE, params, extraParam);
  };

  const updateServiceData = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE + pid + '/', data);
  };

  const getDocServices = async (id) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + id + '/services/');
  };

  const getLocationByAdmin = async (id, params) => {
    return auth_axios.get(endPoints.ADMINPROFILE(id) + 'locations/', {
      params: { ...params },
    });
  };

  const getAvailableSlots = async (params) => {
    return auth_axios.get(endPoints.GETAVAILABLESLOT, {
      params: { ...params },
    });
  };

  const getClinicAdmin = async (id, params) => {
    return auth_axios.get(BASE_URL + endPoints.UPDATELOCATIOB(id) + 'admins/', {
      params: { ...params },
    });
  };

  const getAppointment = async (params) => {
    let url = BASE_URL + endPoints.GETAPPOINTMENT + params;
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getAppointmentDetail = async (id) => {
    return auth_axios.get(endPoints.GETAPPOINTMENT + id);
  };

  const createAppointment = async (data) => {
    return await auth_axios.post(endPoints.GETAPPOINTMENT, data);
  };

  const updateAppointment = async (id, data) => {
    return await auth_axios.patch(endPoints.GETAPPOINTMENT + id + '/', data);
  };

  const recordPayment = async (id, data) => {
    return await auth_axios.patch(endPoints.GETAPPOINTMENT + id + '/record_payment/', data);
  };

  const cancelAppointment = async (id, data) => {
    return await auth_axios.post(endPoints.GETAPPOINTMENT + id + '/cancel/');
  };

  const singleDataService = async (id, pid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE + pid + '/');
  };

  const deleteService = async (id, questionId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE + questionId + '/');
  };

  const getBehaviourQuestionCategories = async (id, params) => {
    return auth_axios.get(endPoints.BEHAVIORQUESTIONSCATEGORIES);
  };

  const prescriptionMDTSummerygetSingle = async (id, summeryId) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSUMMERYGETSINGLE(id, summeryId));
  };

  const getCarePlanTemplate = async (id) => {
    return auth_axios.get(endPoints.CAREPLAN + id + '/');
  };

  const createPRC = async (params) => {
    return auth_axios.post(endPoints.PRC, params);
  };

  const getPRC = async (page) => {
    let url = BASE_URL + endPoints.PRC;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const updatPRCDATA = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEPRC(id), data);
  };

  const deletePRC = async (id) => {
    return await auth_axios.delete(endPoints.UPDATEPRC(id));
  };

  const createCarePlan = async (data) => {
    return await auth_axios.post(endPoints.CAREPLAN, data);
  };

  const getCarePlan = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.CAREPLAN, {
      params: { ...pageQry },
    });
  };

  const getServiceDoctor = async (pages) => {
    return auth_axios.get(endPoints.GETSERVICEDOCTORES, {
      params: { ...pages },
    });
  };

  const deleteCarePlan = async (id) => {
    return auth_axios.delete(endPoints.CAREPLAN + id + '/');
  };

  const updateCarePlan = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.CAREPLAN + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const createNotesTemplate = async (params) => {
    return auth_axios.post(endPoints.NOTESTEMPLATE, params);
  };

  const searchNotesTemplate = async (qry) => {
    return auth_axios.get(endPoints.NOTESTEMPLATE + 'search/', {
      params: {
        search_str: qry,
      },
    });
  };
  const deleteNotesTemplate = async (id) => {
    return auth_axios.delete(endPoints.NOTESTEMPLATE + id + '/');
  };

  const updateNotesTemplate = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.NOTESTEMPLATE + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const notesTemplateGet = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.NOTESTEMPLATE, {
      params: { ...pageQry },
    });
  };

  const getConsultation = async (pages) => {
    return auth_axios.get(endPoints.CONSUTDATAGET, { params: { ...pages } });
  };

  const getOrders = async (pages) => {
    return auth_axios.get(endPoints.ORDERS, { params: { ...pages } });
  };

  const getCoupons = async () => {
    return auth_axios.get(endPoints.COUPONS);
  };

  const searchOrders = async (pages) => {
    return auth_axios.get(endPoints.ORDERS + 'search/', {
      params: { ...pages },
    });
  };

  const exportOrderCsv = async () => {
    return auth_axios.get(endPoints.ORDERS + 'export_to_csv/');
  };

  const getZohoMeetingsForPatient = async (id, params) => {
    return auth_axios.get(endPoints.GETPATIENTMEETINGS(id), { params });
  };

  const getZohoMeeting = async (id) => {
    return auth_axios.get(endPoints.GETZOHOMEETINGS + id);
  };

  const createZohoMeeting = async (params) => {
    return auth_axios.post(endPoints.CREATEZOHOMEETING, params);
  };

  const createZohoBooking = async (params, id) => {
    return auth_axios.post(endPoints.CREATEPATIENTMEETINGS(id), params);
  };

  const updateZohoBooking = async (params, id) => {
    return auth_axios.post(endPoints.UPDATEPATIENTMEETINGS(id), params);
  };

  const deleteZohoMeeting = async (id) => {
    return auth_axios.delete(endPoints.DELETEZOHOMEETING + id + '/');
  };

  const createRegimeTemplate = async (params) => {
    return auth_axios.post(endPoints.REGIMETEMPLATE, params);
  };
  const createAppointmentService = async (params) => {
    return auth_axios.post(endPoints.APPOINTMENTSERVICE, params);
  };

  const getAppointmentService = async (qry, enable) => {
    let url = BASE_URL + endPoints.APPOINTMENTSERVICE;

    return auth_axios.get(endPoints.APPOINTMENTSERVICE, {
      params: {
        page: qry,
        enable: enable,
      },
    });
  };

  const getServiceDropdownSearch = async (qry) => {
    return auth_axios.get(endPoints.APPOINTMENTSERVICE + 'search/', {
      params: {
        search_str: qry,
        enable: true,
      },
    });
  };

  const updatAppointmentService = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEAPPOINTMENTSERVICE(id), data);
  };

  const deleteAppointmentService = async (id) => {
    return await auth_axios.delete(endPoints.UPDATEAPPOINTMENTSERVICE(id));
  };

  const updateRegimeTemplate = async (id, params) => {
    return auth_axios.patch(endPoints.REGIMETEMPLATE + id + '/', params);
  };

  const deleteRegimeTemplate = async (id) => {
    return auth_axios.delete(endPoints.REGIMETEMPLATE + id + '/');
  };

  const getAllDoctorList = async () => {
    return auth_axios.get(endPoints.GETALLDOCTOR);
  };

  const getSlotsCount = async (bookingDate, doctorId) => {
    return auth_axios.get(endPoints.SLOTSCOUNT(bookingDate, doctorId));
  };

  const getAppointmentSchedule = async (consult_datetime, doctor_id) => {
    return auth_axios.get(endPoints.APPOINTMENTSSCHEDULE(consult_datetime, doctor_id));
  };

  const getSlotsConsultation = async (date, doctorId, locationId) => {
    return auth_axios.get(endPoints.GETSLOTSCONSULTATION(date, doctorId, locationId));
  };

  const getTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.REGIMETEMPLATE,
      method: 'get',
      params: { ...pages },
    });
  };

  const searchTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.REGIMETEMPLATE + 'search/',
      method: 'get',
      params: { ...pages },
    });
  };

  const searchProfileQuestionList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONSSEARCH,
      method: 'get',
      params: { ...pages },
    });
  };

  const getRegimeTemplate = async (id) => {
    return auth_axios.get(endPoints.REGIMETEMPLATE + id + '/');
  };

  const createTaskCategory = async (params) => {
    return auth_axios.post(endPoints.TASKCATEGORY, params);
  };

  const updateTaskCategory = async (id, params) => {
    return auth_axios.patch(endPoints.TASKCATEGORY + id + '/', params);
  };

  const getTaskCategoryList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.TASKCATEGORY,
      method: 'get',
      params: { ...pages },
    });
  };

  const getTaskCategory = async (id) => {
    return auth_axios.get(endPoints.TASKCATEGORY + id + '/');
  };

  const deleteTaskCategory = async (id) => {
    return auth_axios.delete(endPoints.TASKCATEGORY + id + '/');
  };

  const searchTaskCategoryList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.TASKCATEGORY + 'search/',
      method: 'get',
      params: { ...pages },
    });
  };

  const searchListCategory = async () => {
    return auth_axios({
      url: BASE_URL + endPoints.TASKCATEGORY + 'search_list/',
      method: 'get',
    });
  };

  const createTaskTemplate = async (params) => {
    return auth_axios.post(endPoints.TASKTEMPLATE, params);
  };

  const updateTaskTemplate = async (id, params) => {
    return auth_axios.patch(endPoints.TASKTEMPLATE + id + '/', params);
  };

  const getTaskTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.TASKTEMPLATE,
      method: 'get',
      params: { ...pages },
    });
  };

  const getTaskTemplate = async (id) => {
    return auth_axios.get(endPoints.TASKTEMPLATE + id + '/');
  };

  const deleteTaskTemplate = async (id) => {
    return auth_axios.delete(endPoints.TASKTEMPLATE + id + '/');
  };

  const searchTaskTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.TASKTEMPLATE + 'search/',
      method: 'get',
      params: { ...pages },
    });
  };

  const searchListTaskTemplate = async () => {
    return auth_axios({
      url: BASE_URL + endPoints.TASKTEMPLATE + 'search_list/',
      method: 'get',
    });
  };
  const createDietTemplate = async (params) => {
    return auth_axios.post(endPoints.DIETPLANTEMPLATE, params);
  };

  const getDietTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.DIETPLANTEMPLATE,
      method: 'get',
      params: { ...pages },
    });
  };

  const getDietTemplate = async (id) => {
    return auth_axios.get(endPoints.DIETPLANTEMPLATE + id + '/');
  };

  const searchDietTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.DIETPLANTEMPLATE + 'search/',
      method: 'get',
      params: { ...pages },
    });
  };

  const deleteDietTemplate = async (id) => {
    return auth_axios.delete(endPoints.DIETPLANTEMPLATE + id + '/');
  };

  const updateDietTemplate = async (id, params) => {
    return auth_axios.patch(endPoints.DIETPLANTEMPLATE + id + '/', params);
  };

  const updateZohoMeeting = async (id, params) => {
    return auth_axios.put(endPoints.UPDATEZOHOMEETING + id + '/', params);
  };
  const getWeightGoalId = async (id) => {
    return auth_axios.get(endPoints.GETWEIGHTGOALID(id));
  };
  const userCallLogsUpdate = async (id, params) => {
    return auth_axios.post(endPoints.USERCALLLOGS(id), params);
  };
  const activityLogsCreate = async (id, params) => {
    return auth_axios.post(endPoints.ACTIVITYLOGS(id), params);
  };

  const createProfileAnswers = async (id, params) => {
    return auth_axios.post(endPoints.PROFILEANSWERS(id), params);
  };

  const createSubscription = async (id, params) => {
    return auth_axios.post(endPoints.SUBSCRIPTION(id), params);
  };

  const createTasks = async (params, id) => {
    return auth_axios.post(endPoints.TASKS(id), params);
  };

  const callLogData = async (params, id) => {
    return auth_axios.get(endPoints.CALLLOG(id), params);
  };

  // const createBehaviourQuestion = async (params, id) => {
  //   return auth_axios.post(endPoints.BEHAVIOURQUESTION(id), params);
  // };

  const createBehaviourQuestion = async (params) => {
    return auth_axios.post(endPoints.BEHAVIOURQUESTION, params);
  };

  const teamGet = async (id) => {
    return auth_axios.get(endPoints.TEAMGET(id));
  };

  const prescriptionDoctorList = async (id, listFor) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/assigned_admins/?role=' + listFor);
  };
  const getConsultationDataApi = async (pages) => {
    return auth_axios.get(endPoints.CONSUTDATAGET, { params: { ...pages } });
  };

  // const getOrderDetail = async (pages) => {
  //   return auth_axios.get(endPoints.CONSUTDATAGET, { params: { ...pages } });
  // };

  const getOrderDetail = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/orders/');
  };

  const getSelectedOrderDetail = async (id, oid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/orders/' + oid);
  };

  const updateOrderDetail = async (id, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/orders/', params);
  };
  const prescriptionCreate = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS, params);
  };

  const consultationCreate = async (params, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/', params);
  };

  const teleConsultationCreate = async (params, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/tele_consultations/', params);
  };

  const consultationUpdate = async (params, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/' + params.id + '/checkin/', params);
  };

  const consultationEdit = async (params, id, consultatinId) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'consultation' + '/' + consultatinId + '/', params);
  };

  const getCarePlanDropDownData = async () => {
    return auth_axios.get(endPoints.CAREPLAN + 'meta/');
  };

  const consultationList = async (params, patientId) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/appointments/', { params: { ...params } });
  };
  const teleConsultationList = async (params, patientId) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/tele_consultations/', { params: { ...params } });
  };
  const createFollowUp = async (consultationId, patientId, params) => {
    return auth_axios.post(
      endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/create_follow_up/',
      { ...params }
    );
  };
  const consultationDetail = async (consultationId, patientId) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/');
  };

  // patients/3/tele_consultations/29/room_sessions?room_id=657dee912230de2e7bd2db35

  const teleConsultationDetail = async (consultationId, patientId, params) => {
    return auth_axios.get(
      endPoints.PATIENTLIST + patientId + '/tele_consultations/' + consultationId + '/room_sessions/',
      {
        params: { ...params },
      }
    );
  };

  const modalTeleConsultationDetail = async (consultationId, patientId, params) => {
    return auth_axios.get(
      endPoints.PATIENTLIST + patientId + '/tele_consultations/' + consultationId + '/recording_assets/',
      {
        params: { ...params },
      }
    );
  };

  const modalDownloadButtonDetail = async (consultationId, patientId, params) => {
    return auth_axios.get(
      endPoints.PATIENTLIST + patientId + '/tele_consultations/' + consultationId + '/presigned_url_of_recording/',
      {
        params: { ...params },
      }
    );
  };

  const consultationDelete = async (consultationId, patientId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/');
  };

  const consultationClose = async (consultationId, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/closed/');
  };

  const mdtSUmmaryUnpublish = async (id, mdt_id) => {
    return auth_axios.post(endPoints.PRESCRIPTIONSUMMERYUNPUBLISH(id, mdt_id));
  };
  const mdtSUmmaryPublish = async (id, mdt_id) => {
    return auth_axios.post(endPoints.PRESCRIPTIONSUMMERYPUBLISH(id, mdt_id));
  };

  const behaviorQuestionCreate = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE, params);
  };

  const statusActivate = async (id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'activate' + '/');
  };

  const statusOnboarding = async (id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'onboarding' + '/');
  };

  const statusLapsed = async (id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'lapsed' + '/');
  };

  const createNotify = async (segmentId) => {
    return auth_axios.post(BASE_URL + endPoints.SEGMENTDEFINITION + segmentId + '/notify/');
  };

  const createRegisterEvent = async (segmentId) => {
    return auth_axios.post(BASE_URL + endPoints.SEGMENTDEFINITION + segmentId + '/register_event/');
  };

  const updateNotify = async (segmentId, params) => {
    return auth_axios.patch(BASE_URL + endPoints.SEGMENTDEFINITION + segmentId + '/', params);
  };

  const notes = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES, params);
  };

  const statusPublish = async (id, prescriptionId) => {
    return auth_axios.post(
      endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + prescriptionId + '/' + 'publish' + '/'
    );
  };

  const statusQuestionPublish = async (id, prescriptionId) => {
    return auth_axios.post(
      endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + prescriptionId + '/' + 'publish' + '/'
    );
  };

  const statusDietPlanPublish = async (id, DietPlanId) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN + DietPlanId + '/' + 'publish' + '/');
  };

  const DietPlanStatusPublish = async (id, DietPlanId) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + DietPlanId + '/' + 'publish' + '/');
  };

  const statusUnpublished = async (id, prescriptionId) => {
    return auth_axios.post(
      endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + prescriptionId + '/' + 'unpublish' + '/'
    );
  };

  const createMedicines = async (params) => {
    return auth_axios.post(endPoints.MEDICINES, params);
  };

  const createDepartmentModel = async (params) => {
    return auth_axios.post(endPoints.DEPARTMENTS, params);
  };

  const createDietPlan = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN, params);
  };

  const DietPlanCreate = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS, params);
  };

  const DietPlanCreatePublish = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + 'save_publish' + '/', params);
  };

  const DietPlanUpdatePublish = async (id, pid, data) => {
    return auth_axios.patch(
      endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + pid + '/update_publish' + '/',
      data
    );
  };

  const getDietPlan = async (id, params, pid) => {
    return auth_axios.get(endPoints.dietPlanList(id, pid), {
      params: { ...params },
    });
  };

  const getPublishedDietPlansForUser = async (id, params, pid) => {
    return auth_axios.get(endPoints.publishedDietPlanList(id, pid), {
      params: { ...params },
    });
  };

  const getDietPlanTemplateList = async (id, params, pid) => {
    return auth_axios.get(endPoints.dietPlanTemplateList(id, pid), {
      params: { ...params },
    });
  };

  const createClinicModel = async (params) => {
    return auth_axios.post(endPoints.GETLOCATIONS, params);
  };

  const createMedicinesApprove = async (id) => {
    return auth_axios.post(endPoints.MEDICINES + id + '/publish/');
  };
  const createMedicinesUnApprove = async (id) => {
    return auth_axios.post(endPoints.MEDICINES + id + '/unpublish/');
  };
  const createMedicinesDraft = async (params) => {
    return auth_axios.post(endPoints.MEDICINES, params);
  };

  const createPatient = async (params) => {
    return auth_axios.post(endPoints.PATIENTLIST, params);
  };

  const createPatientStatus = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'update_status' + '/', params);
  };
  const showNoShowPatientStatus = async (params, id, consultatinId) => {
    return auth_axios.post(
      endPoints.PATIENTLIST + id + '/' + 'consultation' + '/' + consultatinId + '/' + 'update_patient_attendance/',
      params
    );
  };

  const createProfileQuestion = async (params) => {
    return auth_axios.post(endPoints.PROFILEQUESTIONS, params);
  };

  const createWebEngage = async (audience, notificationTemplateId, params) => {
    return auth_axios.post(
      endPoints.WEBENGAGE + audience + '/notification_templates/' + notificationTemplateId + '/notification_messages/',
      params
    );
  };

  const createFcm = async (audience, notificationTemplateId, params) => {
    return auth_axios.post(
      endPoints.FCM + audience + '/notification_templates/' + notificationTemplateId + '/notification_messages/',
      params
    );
  };

  const createPatientGoal = async (id, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.GOALCREATION, params);
  };
  const createPatientReport = async (id, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.REPORTTAB, params);
  };

  const createMeasuringEvent = async (params) => {
    return auth_axios.post(endPoints.MEASURINGEVENT, params);
  };

  const createGoalCreation = async (params) => {
    return auth_axios.post(endPoints.GOALCREATION, params);
  };

  const createProfile = async (params) => {
    return auth_axios.post(endPoints.ADMINPROFILE(), params);
  };

  const createArticles = async (params) => {
    return auth_axios.post(endPoints.ARTICLES, params);
  };

  const createCategories = async (params) => {
    return auth_axios.post(endPoints.ARTICLECATEGORY, params);
  };

  const createNotesCategories = async (params) => {
    return auth_axios.post(endPoints.NOTESCATEGORY, params);
  };

  const createDepartmentStatus = async (params) => {
    return auth_axios.post(endPoints.GETALLDEPARTSTATUSES, params);
  };

  const searchMedicinesName = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.MEDICINESSEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const CompeleteTasks = async (id, taskId) => {
    return auth_axios.post(endPoints.TASKS(id) + taskId + '/' + 'completed/');
  };

  const createArticleTags = async (params) => {
    return auth_axios.post(endPoints.ARTICLETAGS, params);
  };

  // delete apis here

  const deleteTask = async (pid, id) => {
    return auth_axios.delete(endPoints.TASKS(pid) + id + '/');
  };

  const prescriptionMDTSummeryDelete = async (id, summeryId) => {
    return auth_axios.delete(endPoints.PRESCRIPTIONSUMMERYGETSINGLE(id, summeryId));
  };

  const deleteMeasuringEvent = async (id) => {
    return auth_axios.delete(endPoints.MEASURINGEVENT + id + '/');
  };

  const deletebanner = async (id) => {
    return auth_axios.delete(endPoints.MOBILEBANNER + id + '/');
  };
  const deleteGoals = async (id) => {
    return auth_axios.delete(endPoints.GOALCREATION + id + '/');
  };

  const deleteNotes = async (id, notesId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES + notesId + '/');
  };
  const deleteAttatchGoal = async (id, notesId, params) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.GOALCREATION + notesId + '/', {
      params: { ...params },
    });
  };
  const profileQuestionDelete = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONS + id + '/',
      method: 'delete',
    });
  };
  const profileBehaviorQuestionDelete = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIOURQUESTION + id + '/',
      method: 'delete',
    });
  };

  const deleteArticleImage = async (id) => {
    return auth_axios.delete(`${endPoints.ARTICLES + id}/remove_image/`);
  };

  const deleteQuestions = async (id, questionId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + questionId + '/');
  };

  const deleteDietPlan = async (id, dietPlanId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN + dietPlanId + '/');
  };

  const deleteDietsPlan = async (id, dietPlanId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + dietPlanId + '/');
  };

  const deleteMedicines = async (id) => {
    return auth_axios.delete(`${endPoints.MEDICINES + id}/`);
  };
  const deletePrescription = async (id, questionId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + questionId + '/');
  };

  const articleCategoryDelete = async (id) => {
    return auth_axios.delete(`${endPoints.ARTICLECATEGORY + id}/`);
  };

  const notesCategoryDelete = async (id) => {
    return auth_axios.delete(`${endPoints.NOTESCATEGORY + id}/`);
  };

  const departmentStatusDelete = async (id) => {
    return auth_axios.delete(`${endPoints.GETALLDEPARTSTATUSES + id}/`);
  };

  const articleTagsDelete = async (id) => {
    return auth_axios.delete(`${endPoints.ARTICLETAGS + id}/`);
  };

  // get apis here

  const getNotes = async (id, page) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES, {
      params: { ...page },
    });
  };

  const getCallLog = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/patient_bmi_performance/');
  };

  const getActivePrescription = async (id, page) => {
    return auth_axios.get(endPoints.ACTIVEPRESCTIPTION(id));
  };

  const duplicateRegimeQuestions = async (id, question_id) => {
    return auth_axios.get(endPoints.DUPLICATEQUESTION(id, question_id));
  };

  const searchTaskAssign = async (id, qry) => {
    return auth_axios.get(endPoints.SEAECHTASKASSIGNTOSEARCH(id), {
      params: { ...qry },
    });
  };

  const getPublishedPrescriptionData = async (id) => {
    return auth_axios.get(endPoints.PUBLISHEDPRESCRIPTION(id));
  };

  const allStatus = async (status) => {
    return auth_axios.get(endPoints.STATUS, { params: { ...status } });
  };

  const getGoals = async (page) => {
    return auth_axios.get(endPoints.GOALCREATION, { params: { ...page } });
  };
  const getMedicinecategories = async (page) => {
    return auth_axios.get(endPoints.MEDICINECATEGORIES, {
      params: { ...page },
    });
  };

  const getWebEngageData = async (audience) => {
    return auth_axios.get(endPoints.WEBENGAGE + audience + '/notification_templates/');
  };

  const getFcmData = async (audience) => {
    return auth_axios.get(endPoints.FCM + audience + '/notification_templates/');
  };

  const getSingleWebEngageData = async (audience, id) => {
    return auth_axios.get(endPoints.WEBENGAGE + audience + '/notification_templates/' + id + '/');
  };

  const getSingleFcmData = async (audience, id) => {
    return auth_axios.get(endPoints.FCM + audience + '/notification_templates/' + id + '/');
  };

  const getCareSearch = async (qry) => {
    return auth_axios.get(endPoints.CAREPLAN + 'search/', {
      params: {
        search_str: qry,
      },
    });
  };

  const getAttatchgoal = async (id, page) => {
    return auth_axios.get(endPoints.Attatchgoal(id));
  };

  const getAssignTask = async (page) => {
    return auth_axios.get(endPoints.ASSIGNTASK, {
      params: { ...page },
    });
  };

  const TaskRescheduleDate = async (params) => {
    return auth_axios.post(endPoints.TASKRESCHEDULEDATE, params);
  };

  const getSearchSegment = async (qry) => {
    return auth_axios.get(BASE_URL + endPoints.SEGMENTDEFINITIONSEARCH, {
      params: {
        ...qry,
      },
    });
  };

  const getSegment = async () => {
    return auth_axios.get(BASE_URL + endPoints.SEGMENTDEFINITION);
  };

  const showTasks = async (id, page) => {
    return auth_axios.get(endPoints.TASKS(id), { params: { ...page } });
  };
  const getPresignedUrl = async (params) => {
    return auth_axios.get(endPoints.PRESIGNERURL, { params: { ...params } });
  };
  const weightGraph = async (id, date) => {
    return auth_axios.get(endPoints.WEIGHTGRAPH(id), { params: { ...date } });
  };

  const getDashBoardConfigData = async () => {
    return auth_axios.get(endPoints.DASHBOARDCONFIG);
  };

  const getAUDITLOG = async (pageQry) => {
    return auth_axios.get(endPoints.AUDITLOG, { params: { ...pageQry } });
  };

  const prescriptionList = async (id, params, pid) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSLIST(id, pid), {
      params: { ...params },
    });
  };
  const prescribedMedicine = async (id, params) => {
    return auth_axios.get(endPoints.PRESCRIBEDMEDICINE(id), {
      params: { ...params },
    });
  };

  const getCarePlanNotification = async (id, pages) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + id + '/notifications/', {
      params: { ...pages },
    });
  };

  const getCarePlanMarkDoneNotification = async (id, notificationId, params) => {
    return auth_axios.patch(endPoints.ADMINPROFILE() + id + '/notifications/' + notificationId + '/mark/', params);
  };

  const behaviourQuestionList = async (id, params, pid) => {
    return auth_axios.get(endPoints.behaviourList(id, pid), {
      params: { ...params },
    });
  };

  const prescriptionMedicine = async (id, pid, mid, params) => {
    return auth_axios.get(endPoints.PRESCRIPTIONMEDICINE(id, pid, mid), {
      params: { ...params },
    });
  };

  const patientProfileTimeline = async (id, page) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTPROFILETIMELINE, { params: { ...page } });
  };

  const prescriptionData = async (id, pid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + pid + '/');
  };

  const getResource = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.STATUS, {
      params: { ...pageQry },
    });
  };

  const createResourceStatus = async (data) => {
    return await auth_axios.post(endPoints.STATUS, data);
  };

  const updateResourceStatus = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.STATUS + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const deleteResourceStatus = async (id) => {
    return auth_axios.delete(endPoints.STATUS + id + '/');
  };

  const questionData = async (id, pid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + pid + '/');
  };

  const questionDataPrevious = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATEPREVIOUS);
  };

  const medicineCategoriesGet = async () => {
    return auth_axios.get(endPoints.MEDICINECATEGORIES);
  };

  const getMedicines = async (params) => {
    return auth_axios.get(endPoints.MEDICINES + '?status=published');
  };

  const getMedicinesSetting = async (pageQry, params) => {
    return auth_axios.get(endPoints.MEDICINES, { params: { ...pageQry } });
  };

  const getDayParts = async (pageQry, params) => {
    return auth_axios.get(endPoints.DAYPART, { params: { ...pageQry } });
  };

  const search = async (method, qry, id, labelSearch) => {
    let searchQry = endPoints[method];
    if (id) {
      searchQry = endPoints[method](id);
    }
    return auth_axios.get(searchQry, {
      params: qry,
    });
  };

  const getMeasuringEvent = async (pages) => {
    return auth_axios.get(endPoints.MEASURINGEVENT, { params: { ...pages } });
  };
  const getBannerDetail = async (pages) => {
    return auth_axios.get(endPoints.MOBILEBANNER, { params: { ...pages } });
  };
  const activityLogsOnchange = async (id, params) => {
    return auth_axios.get(endPoints.ACTIVITYLOGSONCHANGE(id), {
      params: { ...params },
    });
  };

  const getGoalEvent = async () => {
    return auth_axios.get(endPoints.GOALCREATION);
  };

  const searchTags = async (qry) => {
    return auth_axios.get(endPoints.SEARCHTAGS, {
      params: {
        search_str: qry,
      },
    });
  };

  const getProfileQuestions = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONS,
      method: 'get',
      params: { ...pages },
    });
  };

  const getBehaviourQuestions = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIOURQUESTION,
      method: 'get',
      params: { ...pages },
    });
  };

  const searchCategory = async (qry) => {
    return auth_axios.get(endPoints.SEARCATEGORIES, {
      params: {
        search_str: qry,
      },
    });
  };

  const searchNotesCategory = async (qry) => {
    return auth_axios.get(endPoints.NOTESCATEGORY + 'search/', {
      params: {
        title: qry,
      },
    });
  };

  const getAdminProfilesCount = async (filters) => {
    return auth_axios.get(BASE_URL + endPoints.ADMINPROFILECOUNT, {
      params: { ...filters },
    });
  };

  const getPatientCount = async (qryParams) => {
    return auth_axios.get(BASE_URL + endPoints.PATIENTCOUNT, {
      params: {
        ...qryParams,
      },
    });
  };

  const getDashBoardData = async (qryParams) => {
    return auth_axios({
      url: BASE_URL + endPoints.DASHBOARD,
      method: 'get',
      params: { ...qryParams },
    });
  };

  const currentAdminProfile = async (token) => {
    return auth_axios({
      url: BASE_URL + endPoints.USERPROFILE,
      method: 'get',
    });
  };

  const getAdminProfile = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ADMINPROFILE(id),
      method: 'get',
    });
  };

  const getPatientProfile = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTLIST + id + '/',
      method: 'get',
    });
  };

  const getPatientDetails = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/');
  };

  const getTeamWorkingHours = async (id) => {
    return auth_axios.get(endPoints.TEAMWORKINGHOUR(id));
  };

  const deleteTeamWorkingHours = async (id) => {
    return auth_axios.delete(endPoints.DELETETEAMWORKINGHOUR(id));
  };

  const getPatientgoals = async (id, goalid, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.PATIENTGOALS(id, goalid),
      method: 'get',
      params: { ...params },
    });
  };

  const getPatientReports = async (id, reportid, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.GETPATIENTREPORTS(id, reportid),
      method: 'get',
      params: { ...params },
    });
  };

  const getDepartment = async (page) => {
    let url = BASE_URL + endPoints.DEPARTMENTS;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const searchDepartments = async (query) => {
    let url = BASE_URL + endPoints.SEARCHDEPARTMENTS;
    if (query) {
      url += '?name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getPatients = async (qryParams) => {
    return auth_axios.get(BASE_URL + endPoints.PATIENTLIST, {
      params: {
        ...qryParams,
      },
    });
  };

  const getAdminProfiles = async (qryParams) => {
    return auth_axios.get(BASE_URL + endPoints.ADMINPROFILE(), {
      params: { ...qryParams },
    });
  };

  const getSignleArticle = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLES + `${id}/`,
      method: 'get',
    });
  };

  const getArticleDeeplink = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLES + `${id}/` + endPoints.DEEPLINK,
      method: 'get',
    });
  };

  const getReportDeeplink = async (id, reportid) => {
    return auth_axios({
      url: BASE_URL + endPoints.GETPATIENTREPORTS(id, reportid) + endPoints.DEEPLINK,
      method: 'get',
    });
  };

  const getPrescriptionDeeplink = async (id, pid) => {
    return auth_axios({
      url: BASE_URL + endPoints.PRESCRIPTIONSLIST(id, pid) + endPoints.DEEPLINK,
      method: 'get',
    });
  };

  const getDietPlanDeeplink = async (id, pid) => {
    return auth_axios({
      url: BASE_URL + endPoints.DIETPLANLIST(id, pid) + endPoints.DEEPLINK,
      method: 'get',
    });
  };

  const getDeeplink = async () => {
    return auth_axios({
      url: BASE_URL + endPoints.DEEPLINK,
      method: 'get',
    });
  };

  const getArticles = async (qryParams) => {
    return await auth_axios({
      url: BASE_URL + endPoints.ARTICLES,
      method: 'get',
      params: { ...qryParams },
    });
  };

  const getArticlesCount = async (filters) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLECOUNT,
      method: 'get',
      params: { ...filters },
    });
  };

  const searchGoals = async (page) => {
    return auth_axios.get(endPoints.GOALSEARCH, { params: { ...page } });
  };

  const getSearchArticles = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.SEARCHARTICLE + params,
      method: 'get',
    });
  };

  const searchPatientGoals = async (id, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.PATIENTGOALSSEARCH(id),
      method: 'get',
      params: { ...params },
    });
  };

  const searchMedicines = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.MEDICINESSEARCH + '?status=published',
      params: { search_str: params },
      method: 'get',
    });
  };

  const dayPartGet = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.DAYPARTSSEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const searchBEHAVIOURQUESTION = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIORQUESTIONSEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const searchMedicinesByCategories = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.MEDICINECATEGORIESEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const getSearchTeam = async (qry) => {
    return auth_axios.get(BASE_URL + endPoints.SEARCHTEAM, {
      params: {
        ...qry,
      },
    });
  };

  const getSearchPatients = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTSEARCH,
      method: 'get',
      params: { search_str: params },
    });
  };

  const getSearchPatientsOfHealthCoach = async (params1, params2) => {
    return auth_axios({
      url: BASE_URL + endPoints.ADMINPATIENTSEARCH,
      method: 'get',
      params: { search_str: params1, admin_id: params2 },
    });
  };

  const getSearchTasks = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.TASKSEARCH,
      method: 'get',
      params: { ...pages },
    });
  };

  const articleCategoryGet = async (page) => {
    let url = BASE_URL + endPoints.ARTICLECATEGORY;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const notesCategoryGet = async (page) => {
    let url = BASE_URL + endPoints.NOTESCATEGORY;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getAllDepartmentStatues = async (page) => {
    let url = BASE_URL + endPoints.GETALLDEPARTSTATUSES;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const searchDepartmentStatues = async (query) => {
    let url = BASE_URL + endPoints.SEARCHDEPARTSTATUSES;
    if (query) {
      url += '?name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const articleTagsGet = async (page, qry) => {
    let url = BASE_URL + endPoints.ARTICLETAGS;
    return auth_axios({
      url: url,
      method: 'get',
      params: {
        ...qry,
        ...(page ? { page: page } : {}),
      },
    });
  };
  const updateOnnboardingStatus = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/', data);
  };

  const prescriptionPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.PRESCRIPTIONPDFDOWNLOAD(id, pid));
  };

  const regimePdfDownload = (id) => {
    return auth_axios.get(endPoints.REGIMEPDFDOWNLOAD(id));
  };

  const summaryPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.SUMMARYPDFDOWNLOAD(id, pid));
  };

  const questionPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.QUESTIONPDFDOWNLOAD(id, pid));
  };

  const duplicateDietplan = async (id, DietPlanId) => {
    return auth_axios.get(endPoints.DUPLICATEDIETPLAN(id, DietPlanId));
  };

  const dietPlanPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.DIETPLANPDFDOWNLOAD(id, pid));
  };
  const dietPlansPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.DIETPLANPDF(id, pid));
  };

  const newDietPlansPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.NEWDIETPLANPDFDOWNLOAD(id, pid));
  };

  // update Apis Here

  const prescriptionMDTSummeryUpdate = async (id, summeryId, params) => {
    return auth_axios.patch(endPoints.PRESCRIPTIONSUMMERYGETSINGLE(id, summeryId), params);
  };

  const updateActivityLogs = async (id, activityId, params) => {
    return auth_axios.patch(endPoints.ACTIVITYLOGACTION(id, activityId), params);
  };

  const updateTasks = async (params, id, tid) => {
    return auth_axios.patch(endPoints.TASKS(id) + tid + '/', params);
  };

  const updatePrescription = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + pid + '/', data);
  };

  const updateBehaviorPrescription = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + pid + '/', data);
  };

  const updateDietPlan = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN + pid + '/', data);
  };

  const DietPlanUpdate = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + pid + '/', data);
  };

  const dashboardConfigration = async (data) => {
    return auth_axios.patch(endPoints.DASHBOARDCONFIG, data);
  };

  const updateMeasuringEvent = async (id, data) => {
    return auth_axios.patch(endPoints.MEASURINGEVENT + id + '/', data);
  };

  const updateBannerData = async (id, data) => {
    return auth_axios.patch(endPoints.MOBILEBANNER + id + '/', data);
  };

  const updateMedicines = async (id, data) => {
    return auth_axios.patch(endPoints.MEDICINES + id + '/', data);
  };

  const updatePatientAssignData = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/', data);
  };

  const updatePatientGOAL = async (id, data, goalid) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'goals' + '/' + goalid + '/', data);
  };

  const updateReports = async (id, data, reportid) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'reports' + '/' + reportid + '/', data);
  };

  const assingAdmin = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.ASIGNADMIN, data);
  };

  const unasignAdmin = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.UNASIGNADMIN, data);
  };

  const updateGoals = async (id, data) => {
    return auth_axios.patch(endPoints.GOALCREATION + id + '/', data);
  };

  const updateQuestionData = async (data, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONS + id + '/',
      method: 'patch',
      data: data,
    });
  };

  const updateBehaviourQuestionData = async (data, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIOURQUESTION + id + '/',
      method: 'patch',
      data: data,
    });
  };

  const updateStatus = async (id, status) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLES + id + '/' + status + '/',
      method: 'patch',
    });
  };

  const updateProfile = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ADMINPROFILE(id),
      method: 'patch',
      data: params,
    });
  };

  const updatePatient = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTLIST + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateArticles = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLES + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateCategories = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLECATEGORY + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateNotesCategories = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.NOTESCATEGORY + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateDepartmentStatus = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.GETALLDEPARTSTATUSES + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateNotes = async (params, id, notesUpdateId) => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTLIST + id + '/' + endPoints.NOTES + notesUpdateId + '/',
      method: 'patch',
      data: params,
    });
  };

  const uploadFileToServer = async (data, url, onUploadProgress) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');

    return axios({
      url: url,
      method: 'put',
      data: data,
      headers: myHeaders,
      onUploadProgress: onUploadProgress,
    });
  };

  const updateTags = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLETAGS + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const createAdherence = async (data) => {
    return await auth_axios.post(endPoints.ADHERENCE, data);
  };
  const getAdherence = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.ADHERENCE, {
      params: { ...pageQry },
    });
  };

  const updateAdherence = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.ADHERENCE + id + '/',
      method: 'patch',
      data: params,
    });
  };
  const deleteAdherence = async (id) => {
    return auth_axios.delete(endPoints.ADHERENCE + id + '/');
  };

  const getDayPartSearch = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.DAYPARTSSEARCH, {
      params: { ...pageQry },
    });
  };

  const searchAttatchGoal = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.GOALSEARCH, {
      params: { ...pageQry },
    });
  };

  const getCategoriesTampletNotes = async (id, notesId) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES + notesId + '/', {});
  };

  const notesCategoryGetByTemplate = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.NOTESCATEGORY + `${id}/template_by_category/`,
      method: 'get',
    });
  };

  const getSingleAppointment = async (id, consultationId) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/consultation/' + consultationId);
  };

  // only patient apis Here ===================================

  const getSignleArticlePatient = async (id) => {
    return patientAxios.get(patientEndPoints.GETARTICLE(id));
  };

  const createDayPart = async (data) => {
    return await auth_axios.post(endPoints.DAYPART, data);
  };

  const createMobileBanner = async (data) => {
    return await auth_axios.post(endPoints.MOBILEBANNER, data);
  };

  const getDayPart = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.DAYPART, {
      params: { ...pageQry },
    });
  };

  const deleteDayPartTime = async (id) => {
    return auth_axios.delete(endPoints.DAYPART + id + '/');
  };

  const updateDayPart = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.DAYPART + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const createSegmentDefinition = async (data) => {
    return await auth_axios.post(endPoints.SEGMENTDEFINITION, data);
  };

  const getLocations = async (page) => {
    let url = BASE_URL + endPoints.GETLOCATIONS;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const searchLocations = async (query) => {
    let url = BASE_URL + endPoints.SEARCHLOCATIONS;
    if (query) {
      url += '?name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const updatePatientLocation = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEPATIENTLOCATION(id), data);
  };

  const getDoctors = async (query, id) => {
    return await auth_axios.get(endPoints.GETDOCTORS(query, id));
  };

  const deleteDepartment = async (id, data) => {
    return await auth_axios.patch(endPoints.DELETEDEPARTMENT(id), data);
  };

  const createDepartment = async (id, data) => {
    return await auth_axios.post(endPoints.CREATEDEPARTMENT(id), data);
  };

  const updateDepartment = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEDEPARTMENT(id), data);
  };

  const updateDepartmentModel = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEDEPARTMENTOBJECT(id), data);
  };

  const deleteDepartmentModel = async (id) => {
    return await auth_axios.delete(endPoints.UPDATEDEPARTMENTOBJECT(id));
  };

  const updateClinic = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATELOCATIOB(id), data);
  };

  const deleteClinic = async (id) => {
    return await auth_axios.delete(endPoints.UPDATELOCATIOB(id));
  };

  const searchPatients = async (query) => {
    let url = BASE_URL + endPoints.PATIENTSEARCH;
    if (query) {
      url += '?full_name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const patientAttendenceStatus = async (consultationId, patientId, selectedStatus) => {
    const body = {
      patient_attendance_status: selectedStatus,
    };
    return auth_axios.post(
      endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/patient_attendance_status/',
      body
    );
  };

  const getVendorDetail = async (id) => {
    return auth_axios.get(BASE_URL + 'vendors/');
  };

  const getOrderSummary = async (id) => {
    return auth_axios.get(BASE_URL + `orders/order_summary?order_id=${id}`);
  };

  const downloadOrder = async (id) => {
    return auth_axios.get(endPoints.DOWNLOADORDER(id));
  };

  const getOrderNotes = async (id) => {
    return auth_axios.get(endPoints.GETNOTES(id));
  };

  const getManualOrder = async (id) => {
    return auth_axios.get(endPoints.GETMANUALORDER(id));
  };

  const getPatientAddress = async (id) => {
    return auth_axios.get(endPoints.GETPATIENTADDRESS(id));
  };

  const getFilteredOrders = async (filters) => {
    //console.log('endPoints.GETFILTEREDORDERS: ', BASE_URL + endPoints.GETFILTEREDORDERS());
    return auth_axios.get(endPoints.GETFILTEREDORDERS(), {
      params: { ...filters },
    });
  };

  const getInventoryMedicines = async (pages) => {
    return auth_axios.get(endPoints.GETINVENTORYMEDICINES(), {
      params: { ...pages },
    });
  };

  const getFormulation = async (id) => {
    return auth_axios.get(endPoints.GETFORMULATION(id));
  };

  const postInventoryMedicines = async (data) => {
    return auth_axios.post(endPoints.INVENTORYCONFIG, data);
  };

  const updateInventoryMedicines = async (id, data) => {
    return auth_axios.patch(endPoints.INVENTORYCONFIG + id + '/', data);
  };

  const searchInventory = async (pages) => {
    return auth_axios.get(endPoints.INVENTORYSEARCH + 'search/', {
      params: { ...pages },
    });
  };

  const getMedicineCount = async () => {
    return auth_axios.get(endPoints.GETMEDICINECOUNT);
  };

  const getMedicineSearchCount = async (params) => {
    return auth_axios.get(endPoints.GETMEDICINESEARCHCOUNT, { params });
  };
  const getDraftVariants = async (params) => {
    return auth_axios.get(endPoints.GETDRAFTVARIANTS, { params });
  };
  const getAllPlans = async ({ page, searchParam, status }) => {
    return auth_axios.get(endPoints.GETALLPLANS({ page, searchParam, status }));
  };
  const createPlan = async (data) => {
    return auth_axios.post(endPoints.CREATEPLAN, data);
  };

  const getAllPlanItems = async () => {
    return auth_axios.get(endPoints.GETALLPLANITEMS);
  };
  const updatePlan = async ({ formValues, id }) => {
    return auth_axios.patch(endPoints.UPDATEPLAN(id), formValues);
  };

  const createPlanPaymentRequest = async (formValues) => {
    return auth_axios.post(endPoints.CREATEPLANPAYMENTREQUEST, formValues);
    // patient Apis ===================================
  };

  const getEnrollmentExperts = async ({ id }) => {
    return auth_axios.get(endPoints.GETEEOPERATORLIST());
  };

  const createPaymentRecord = async (formValues) => {
    return auth_axios.post(endPoints.CREATEPAYMENTRECORD(), formValues);
  };

  const getAllCustomers = async ({ page, searchPhone, searchName, status }) => {
    return auth_axios.get(endPoints.GETALLCUSTOMERS({ page, searchPhone, searchName, status }));
  };

  const getPlanInfoForPatient = async (id) => {
    return auth_axios.get(endPoints.GETPLANINFOFORPATIENT(id));
  };

  const pauseOrExtendPlan = async (id, data) => {
    return auth_axios.post(endPoints.PAUSEOREXTENDPLAN(id), data);
  };
  return {
    getDoctors,
    getServiceDropdownSearch,
    getServiceDoctor,
    patientServiceList,
    deleteService,
    updateServiceData,
    singleDataService,
    patientServiceCreate,
    getServiceCategories,
    getPRC,
    createPRC,
    deletePRC,
    updatPRCDATA,
    deleteAppointmentService,
    createAppointmentService,
    getAppointmentService,
    updatAppointmentService,
    searchPatients,
    patientAttendenceStatus,
    statusDietPlanPublish,
    teleConsultationDetail,
    modalTeleConsultationDetail,
    modalDownloadButtonDetail,
    getDietPlan,
    getPublishedDietPlansForUser,
    updateDietPlan,
    createDietPlan,
    deleteDietPlan,
    getCarePlan,
    createCarePlan,
    updateCarePlan,
    getCarePlanTemplate,
    deleteCarePlan,
    updateClinic,
    deleteClinic,
    updateOrderDetail,
    updateDepartmentModel,
    deleteDepartmentModel,
    updateDepartment,
    createDepartment,
    getFcmData,
    deleteDepartment,
    getDepartment,
    createFcm,
    searchDepartments,
    updatePatientLocation,
    searchLocations,
    getLocations,
    getConfig,
    setZohoCreds,
    getZohoMeetings,
    getZohoMeetingsAvailability,
    getZohoMeetingsForPatient,
    getZohoMeeting,
    getClinicAdmin,
    getAppointment,
    getAppointmentDetail,
    createAppointment,
    updateAppointment,
    recordPayment,
    cancelAppointment,
    getDocServices,
    getLocationByAdmin,
    getAvailableSlots,
    deleteZohoMeeting,
    updateZohoMeeting,
    createZohoMeeting,
    createZohoBooking,
    updateZohoBooking,
    getDayParts,
    updateOnnboardingStatus,
    dayPartGet,
    showNoShowPatientStatus,
    updateReports,
    getBannerDetail,
    getPresignedUrl,
    getCallLog,
    searchBEHAVIOURQUESTION,
    getPatientReports,
    profileQuestionDelete,
    createPatientReport,
    getPatientProfile,
    createPatient,
    createPatientStatus,
    behaviourQuestionList,
    CompeleteTasks,
    getAssignTask,
    statusOnboarding,
    statusLapsed,
    getConsultation,
    getOrders,
    getCoupons,
    searchOrders,
    exportOrderCsv,
    updateOrders,
    updateManualOrders,
    getResource,
    createResourceStatus,
    updateResourceStatus,
    deleteResourceStatus,
    getMedicinecategories,
    updateNotes,
    deleteNotes,
    getNotes,
    statusQuestionPublish,
    notes,
    updateBehaviorPrescription,
    statusPublish,
    statusUnpublished,
    statusActivate,
    getAUDITLOG,
    getBehaviourQuestions,
    getPatients,
    patientProfileTimeline,
    getPatientDetails,
    getTemplateList,
    searchTemplateList,
    getRegimeTemplate,
    createRegimeTemplate,
    getCarePlanDropDownData,
    updateRegimeTemplate,
    teleConsultationCreate,
    teleConsultationList,
    deleteRegimeTemplate,
    getPatientgoals,
    searchMedicinesName,
    teamGet,
    profileBehaviorQuestionDelete,
    updateArticles,
    articleTagsGet,
    getMedicinesSetting,
    articleCategoryGet,
    notesCategoryGet,
    getArticlesCount,
    getArticles,
    getArticleDeeplink,
    getReportDeeplink,
    getPrescriptionDeeplink,
    getDeeplink,
    getSignleArticle,
    articleTagsDelete,
    articleCategoryDelete,
    notesCategoryDelete,
    departmentStatusDelete,
    verifyOTP,
    sendOTP,
    prescribedMedicine,
    questionDataPrevious,
    questionData,
    createArticles,
    createCategories,
    createNotesCategories,
    createArticleTags,
    createDepartmentStatus,
    getAllDepartmentStatues,
    searchDepartmentStatues,
    createProfile,
    dietPlanPdfDownload,
    createNotify,
    createRegisterEvent,
    updateNotify,
    updateCategories,
    updateNotesCategories,
    updateDepartmentStatus,
    updateTags,
    getSearchArticles,
    getAdminProfiles,
    currentAdminProfile,
    getAdminProfilesCount,
    getAdminProfile,
    createMedicinesUnApprove,
    updateProfile,
    deleteArticleImage,
    updateStatus,
    searchDepartments,
    searchTags,
    searchCategory,
    searchNotesCategory,
    getSearchTeam,
    getDashBoardData,
    getPatientCount,
    getSearchPatients,
    getSearchPatientsOfHealthCoach,
    getGoalEvent,
    createBehaviourQuestion,
    notesCategoryGetByTemplate,
    getCategoriesTampletNotes,
    questionPdfDownload,
    search,
    behaviorQuestionCreate,
    createProfileQuestion,
    getProfileQuestions,
    profileQuestionDelete,
    updateQuestionData,
    createMeasuringEvent,
    getMeasuringEvent,
    createGoalCreation,
    deleteMeasuringEvent,
    deletebanner,
    updateMeasuringEvent,
    getGoals,
    deleteGoals,
    updateGoals,
    updatePatientAssignData,
    unasignAdmin,
    updatePatient,
    assingAdmin,
    deleteAttatchGoal,
    getOrderDetail,
    getSelectedOrderDetail,
    getWebEngageData,
    getCarePlanNotification,
    createPatientGoal,
    medicineCategoriesGet,
    createMedicines,
    createDepartmentModel,
    createClinicModel,
    getMedicines,
    createWebEngage,
    createMedicinesApprove,
    deleteMedicines,
    getSingleFcmData,
    updatePatientGOAL,
    updateMedicines,
    searchMedicines,
    getSlotsConsultation,
    getAllDoctorList,
    getSlotsCount,
    getAppointmentSchedule,
    searchGoals,
    consultationCreate,
    consultationUpdate,
    consultationList,
    consultationDetail,
    consultationDelete,
    consultationClose,
    prescriptionCreate,
    prescriptionDoctorList,
    prescriptionList,
    prescriptionData,
    updatePrescription,
    getCarePlanMarkDoneNotification,
    dashboardConfigration,
    getDashBoardConfigData,
    searchMedicinesByCategories,
    weightGraph,
    activityLogsOnchange,
    getCareSearch,
    createTasks,
    showTasks,
    deleteTask,
    getWeightGoalId,
    updateTasks,
    activityLogsCreate,
    createSubscription,
    prescriptionPdfDownload,
    regimePdfDownload,
    createAdherence,
    getAdherence,
    updateAdherence,
    deleteAdherence,
    createProfileAnswers,
    prescriptionMedicine,
    createMobileBanner,
    allStatus,
    updateActivityLogs,
    createDayPart,
    getDayPart,
    deleteDayPartTime,
    updateDayPart,
    getDayPartSearch,
    getAttatchgoal,
    searchTaskAssign,
    getActivePrescription,
    searchAttatchGoal,
    searchPatientGoals,
    updateBehaviourQuestionData,
    duplicateRegimeQuestions,
    deleteQuestions,
    deletePrescription,
    createMedicinesDraft,
    prescriptionSchema,
    prescriptionSummeryCreate,
    prescriptionMDTSummeryList,
    prescriptionMDTSummerygetSingle,
    prescriptionMDTSummeryDelete,
    prescriptionMDTSummeryUpdate,
    mdtSUmmaryPublish,
    mdtSUmmaryUnpublish,
    summaryPdfDownload,
    uploadFileToServer,
    searchNotesTemplate,
    getConsultationDataApi,
    callLogData,
    updateNotesTemplate,
    notesTemplateGet,
    deleteNotesTemplate,
    createNotesTemplate,
    updateBehaviourQuestionData,
    updateBannerData,
    getPublishedPrescriptionData,
    getBehaviourQuestionCategories,
    getSegment,
    consultationEdit,
    getSingleWebEngageData,
    getSearchSegment,
    getDietTemplateList,
    getDietTemplate,
    searchDietTemplateList,
    deleteDietTemplate,
    createDietTemplate,
    updateDietTemplate,
    getDietPlanTemplateList,
    dietPlansPdfDownload,
    deleteDietsPlan,
    DietPlanStatusPublish,
    DietPlanCreate,
    DietPlanUpdate,
    DietPlanCreatePublish,
    DietPlanUpdatePublish,
    duplicateDietplan,
    getTeamWorkingHours,
    deleteTeamWorkingHours,
    getSingleAppointment,
    createTaskCategory,
    updateTaskCategory,
    getTaskCategoryList,
    getTaskCategory,
    deleteTaskCategory,
    searchTaskCategoryList,
    searchTaskTemplateList,
    createTaskTemplate,
    updateTaskTemplate,
    getTaskTemplateList,
    getTaskTemplate,
    deleteTaskTemplate,
    searchListCategory,
    searchListTaskTemplate,
    getSearchTasks,
    TaskRescheduleDate,
    // only patient apis Here ===================================
    getSignleArticlePatient,
    createSegmentDefinition,
    createFollowUp,
    searchProfileQuestionList,
    getOrderSummary,
    getVendorDetail,
    downloadOrder,
    getManualOrder,
    getPatientAddress,
    getOrderNotes,
    getFilteredOrders,
    getDietPlanDeeplink,
    newDietPlansPdfDownload,
    getInventoryMedicines,
    getFormulation,
    postInventoryMedicines,
    updateInventoryMedicines,
    searchInventory,
    getMedicineCount,
    getMedicineSearchCount,
    getDraftVariants,
    getAllPlans,
    createPlan,
    getAllPlanItems,
    updatePlan,
    createPlanPaymentRequest,
    getEnrollmentExperts,
    createPaymentRecord,
    getAllCustomers,
    getPlanInfoForPatient,
    pauseOrExtendPlan,
    userCallLogsUpdate,
  };
}

export default useApiManager;
