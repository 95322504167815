import React from 'react';
import './BorderBtnWithBg.css';

function BorderBtnWithBg(props) {
  const { title, iconImg, className, onClick, style, fromSalesTab = false } = props;
  return (
    <div
      className=""
      data-bs-toggle={fromSalesTab ? undefined : 'offcanvas'}
      data-bs-target={fromSalesTab ? undefined : '#offcanvasRight'}
      aria-controls="offcanvasRight"
    >
      <div onClick={onClick} className={`d-flex align-items-center filterBtn btnParentDiv ${className}`} style={style}>
        <img src={iconImg} className="btnImg" />
        <div>{title}</div>
      </div>
    </div>
  );
}

export default BorderBtnWithBg;
