import React, { useState } from 'react';
import { CoreCheckBox, ReusableButton, BorderBtnWithBg } from 'whealth-core-web/components';

function DashBoardHeader(props) {
  const { data, leftData, showCheckbox, showText } = props;

  const [inputData, setInputData] = useState({ value: '' });

  const handleInputChange = (val) => {
    inputData.value = val;
    setInputData({ ...inputData });
  };

  const renderButtons = () => {
    if (data) {
      return data.map((item, index) => {
        if (item.hide) {
          return;
        }
        return (
          <div key={index}>
            <ReusableButton
              onClick={item.onClick}
              icon={item.icon}
              outline={item.outline}
              title={item.title}
              active={!!item.status}
              style={item.style}
            />
          </div>
        );
      });
    } else {
      return;
    }
  };

  const leftDataMap = () => {
    return leftData.map((item, index) => {
      if (item.hide) {
        return;
      }
      if (item.showBtn) {
        return (
          <BorderBtnWithBg
            onClick={item.onClick}
            className={item.className}
            key={index}
            iconImg={item.icon}
            title={item.title}
            style={item.style}
            fromSalesTab={item?.fromSalesTab}
          />
        );
      } else {
        return (
          <div key={index} className="headingTitleLink" style={item.style}>
            {item.title}
          </div>
        );
      }
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between main-right-part-header">
      <div className="d-flex applyFilterBtn gap-15">
        {showCheckbox && <CoreCheckBox />}
        {leftDataMap()}
      </div>
      <div className="d-flex align-items-center gap-16">
        {showText && <div className="headerTextLite">{showText()}</div>}
        {renderButtons()}
      </div>
    </div>
  );
}

export default DashBoardHeader;
