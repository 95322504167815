import useApiManager from "networking/ApiManager";
import React, { useEffect, useState } from "react";
import { json } from "react-router-dom";
import PlanTile from "./PlanTile";
import { Loader, Pagination } from "whealth-core-web/components";
import { ToastContainer } from "react-toastify";

function getNumberOfPages(totalRecords, recordsPerPage = 10) {
  return Math.ceil(totalRecords / recordsPerPage);
}

const PatientPlanDetails = ({ patientId }) => {
  const [invalidateAPIKey, setInvalidateAPIKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const ApiClient = useApiManager();
  const [plans, setPlans] = useState();
  const planResults = plans?.results || [];
  console.log("planResults", planResults);
  const activePlans = planResults.filter(
    (plan) => plan.status === "active" || plan.status === "paused"
  );
  const upcomingPlans = planResults.filter(
    (plan) => plan.status === "upcoming"
  );
  const inActivePlans = planResults.filter(
    (plan) => plan.status === "inactive"
  );

  const totalRecordCount = planResults.length;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = getNumberOfPages(plans?.count || 1);
  useEffect(() => {
    setIsLoading(true);
    ApiClient.getPlanInfoForPatient(patientId)
      .then((res) => {
        setPlans(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [invalidateAPIKey]);

  const renderUpcomingPlans = () => {
    return (
      <div>
        <div
          className="py-4"
          style={{ fontSize: "1rem", fontWeight: 500, color: "#333" }}
        >
          Upcoming Plans
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {upcomingPlans?.map((plan, index) => {
            return (
              <div key={index}>
                <PlanTile
                  planData={plan}
                  patientId={patientId}
                  showPauseAndExtension={false}
                  setInvalidateAPIKey={setInvalidateAPIKey}
                />
              </div>
            );
          })}
          {upcomingPlans?.length == 0 && (
            <div
              style={{
                color: "#333",
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              No Plans Found
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderActivePlans = () => {
    return (
      <div>
        <div
          className="py-4"
          style={{ fontSize: "1rem", fontWeight: 500, color: "#333" }}
        >
          Active Plans
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {activePlans?.map((plan, index) => {
            return (
              <div key={index}>
                <PlanTile
                  planData={plan}
                  showPauseAndExtension
                  patientId={patientId}
                  setInvalidateAPIKey={setInvalidateAPIKey}
                />
              </div>
            );
          })}
          {activePlans?.length == 0 && (
            <div
              style={{
                color: "#333",
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              No Plans Found
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderPastPlans = () => {
    return (
      <div className="mb-4">
        <div className="py-4" style={{ fontSize: "1rem", fontWeight: 500 }}>
          History
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {inActivePlans?.map((plan, index) => {
            return (
              <div key={index}>
                <PlanTile
                  planData={plan}
                  patientId={patientId}
                  showPauseAndExtension={false}
                  setInvalidateAPIKey={setInvalidateAPIKey}
                />
              </div>
            );
          })}
          {inActivePlans?.length == 0 && (
            <div
              style={{
                color: "#333",
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              No Plans Found
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div style={{ backgroundColor: "transparent" }} className="wrapper">
      <ToastContainer />
      <Loader show={isLoading} />
      {renderUpcomingPlans()}
      {renderActivePlans()}
      {renderPastPlans()}
      <div className="d-flex justify-content-end mb-4">
        <Pagination
          data={undefined}
          length={totalRecordCount}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PatientPlanDetails;

const testPlans = [
  {
    id: 7,
    user: 4534,
    amount_paid: "300.00",
    buy_date: "2024-10-29",
    start_date: "2024-10-31",
    end_date: "2025-04-20",
    pause_duration: 130,
    extension_duration: 10,
    total_pause_count: 13,
    total_extension_count: 1,
    active: false,
    renewal: false,
    plan: {
      id: 2,
      name: "1 Month Plan",
      plan_duration: 2,
      type: "Normal",
      description: "",
    },
    user_plan_items: [
      {
        id: 11,
        product: {
          id: 1453,
          product_name: "cgm",
        },
        quantity: 3,
      },
      {
        id: 12,
        product: {
          id: 1454,
          product_name: "ring",
        },
        quantity: 3,
      },
    ],
    user_plan_pause: [
      {
        id: 4,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-10-31T10:01:45.443878Z",
        created_at: "2024-10-31T10:01:45.443850Z",
      },
      {
        id: 5,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:21:12.110457Z",
        created_at: "2024-11-05T19:21:12.110338Z",
      },
      {
        id: 6,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:23:09.147479Z",
        created_at: "2024-11-05T19:23:09.147223Z",
      },
      {
        id: 7,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:25:11.406317Z",
        created_at: "2024-11-05T19:25:11.406055Z",
      },
      {
        id: 8,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:25:50.881995Z",
        created_at: "2024-11-05T19:25:50.881654Z",
      },
      {
        id: 9,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:27:08.279957Z",
        created_at: "2024-11-05T19:27:08.279748Z",
      },
      {
        id: 10,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:40:45.493355Z",
        created_at: "2024-11-05T19:40:45.493252Z",
      },
      {
        id: 11,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:47:59.248253Z",
        created_at: "2024-11-05T19:47:59.248130Z",
      },
      {
        id: 12,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-12-07",
        end_date: "2024-12-17",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:48:55.933193Z",
        created_at: "2024-11-05T19:48:55.932909Z",
      },
      {
        id: 13,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-12-07",
        end_date: "2024-12-17",
        created_by: {
          id: 31,
          full_name: "Ravi Ranjan",
        },
        updated_by: {
          id: 31,
          full_name: "Ravi Ranjan",
        },
        updated_at: "2024-11-05T19:49:22.062510Z",
        created_at: "2024-11-05T19:49:22.062358Z",
      },
      {
        id: 14,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-12-07",
        end_date: "2024-12-17",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:50:44.517553Z",
        created_at: "2024-11-05T19:50:44.517424Z",
      },
      {
        id: 15,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-12-07",
        end_date: "2024-12-17",
        created_by: null,
        updated_by: null,
        updated_at: "2024-11-05T19:54:01.436738Z",
        created_at: "2024-11-05T19:54:01.436619Z",
      },
      {
        id: 16,
        user_plan: 7,
        type: "pause",
        duration: 10,
        description: null,
        start_date: "2024-12-07",
        end_date: "2024-12-17",
        created_by: {
          id: 31,
          full_name: "Ravi Ranjan",
        },
        updated_by: {
          id: 31,
          full_name: "Ravi Ranjan",
        },
        updated_at: "2024-11-05T19:56:42.056676Z",
        created_at: "2024-11-05T19:56:42.056454Z",
      },
    ],
    user_plan_extension: [
      {
        id: 2,
        user_plan: 7,
        type: "extension",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-10-31T09:45:57.788586Z",
        created_at: "2024-10-31T09:45:57.788562Z",
      },
      {
        id: 3,
        user_plan: 7,
        type: "extension",
        duration: 10,
        description: null,
        start_date: "2024-11-05",
        end_date: "2024-11-15",
        created_by: null,
        updated_by: null,
        updated_at: "2024-10-31T09:48:56.322206Z",
        created_at: "2024-10-31T09:48:56.322183Z",
      },
    ],
    payment: null,
    status: "paused",
    updated_at: "2024-11-05T19:56:42.074731Z",
    created_at: "2024-10-29T07:59:18.442194Z",
  },
  {
    id: 9,
    user: 4534,
    amount_paid: "300.00",
    buy_date: "2024-10-30",
    start_date: null,
    end_date: null,
    pause_duration: 0,
    extension_duration: 0,
    total_pause_count: 0,
    total_extension_count: 0,
    active: false,
    renewal: false,
    plan: {
      id: 35,
      name: "6 Month Plan",
      plan_duration: 180,
      type: "Normal",
      description: "kvnkjfn",
    },
    user_plan_items: [],
    user_plan_pause: [],
    user_plan_extension: [],
    payment: null,
    status: "upcoming",
    updated_at: "2024-10-30T17:47:54.282027Z",
    created_at: "2024-10-30T17:47:54.282004Z",
  },
  {
    id: 10,
    user: 4534,
    amount_paid: "300.00",
    buy_date: "2024-11-06",
    start_date: null,
    end_date: null,
    pause_duration: 0,
    extension_duration: 0,
    total_pause_count: 0,
    total_extension_count: 0,
    active: false,
    renewal: false,
    plan: {
      id: 1,
      name: "1 Month Plan",
      plan_duration: 1,
      type: "Normal",
      description: "",
    },
    user_plan_items: [
      {
        id: 15,
        product: {
          id: 1453,
          product_name: "cgm",
        },
        quantity: 3,
      },
      {
        id: 16,
        product: {
          id: 1454,
          product_name: "ring",
        },
        quantity: 3,
      },
    ],
    user_plan_pause: [],
    user_plan_extension: [],
    payment: null,
    status: "upcoming",
    updated_at: "2024-11-06T18:33:57.517466Z",
    created_at: "2024-11-06T18:33:57.517440Z",
  },
  {
    id: 12,
    user: 4534,
    amount_paid: "300.00",
    buy_date: "2024-11-07",
    start_date: null,
    end_date: null,
    pause_duration: 0,
    extension_duration: 0,
    total_pause_count: 0,
    total_extension_count: 0,
    active: false,
    renewal: false,
    plan: {
      id: 1,
      name: "1 Month Plan",
      plan_duration: 1,
      type: "Normal",
      description: "",
    },
    user_plan_items: [
      {
        id: 19,
        product: {
          id: 1453,
          product_name: "cgm",
        },
        quantity: 3,
      },
      {
        id: 20,
        product: {
          id: 1454,
          product_name: "ring",
        },
        quantity: 3,
      },
    ],
    user_plan_pause: [],
    user_plan_extension: [],
    payment: null,
    status: "upcoming",
    updated_at: "2024-11-07T05:04:20.013066Z",
    created_at: "2024-11-07T05:04:20.013022Z",
  },
];
