import { AdminPanelHeader, SideBar, DashBoardHeader } from "components";
import useApiManager from "networking/ApiManager";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { filters, addIconWhite } from "res/images";

import {
  CommonAlert,
  Pagination,
  PostHeader,
  Loader,
  EmptyView,
  ShowPatientList,
  FilterModal,
  FilterChips,
} from "whealth-core-web/components";
import SearchBox from "whealth-core-web/components/common/SearchBox";
import { useFilter } from "whealth-core-web/components/FilterContext";
import {
  checkObjectLength,
  genrageFilterParams,
  titlizeString,
} from "whealth-core-web/components/Helper";

function Patient() {
  const ApiClient = useApiManager();
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [status, setStatus] = useState({ link: "" });
  const [data, setData] = useState("");
  const [hideEmpty, setHideEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [usersCount, setUsersCount] = useState([]);
  const [countData, setCountData] = useState({});
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [filterData, setFilterData] = useState({});
  const { filterData, setFilterData } = useFilter();
  const [clearFiltersData, setClearFilterData] = useState(false);

  useEffect(() => {
    getAdminProfiles();
  }, [status, currentPage, filterData]);

  useEffect(() => {
    getCounts();
  }, [countData]);

  const getCounts = () => {
    let newArr = Object.keys(countData).reverse((a, b) => a - b);
    let newData = [];
    newArr.map((item) => {
      let newObj = countData[item];
      let title = `${item} (${countData[item].count || 0})`;
      title = titlizeString(title);
      newObj.title = title;
      newObj.link = countData[item].uuid || "";
      newData.push(newObj);
    });

    setUsersCount(newData.sort((a, b) => a.order - b.order));
  };

  useEffect(() => {
    getAdminProfilesCount();
  }, [filterData]);

  useEffect(() => {
    if (location?.state?.showAlert) {
      setIsShowAlert(location.state.showAlert);
    }
  }, [location?.state?.showAlert]);

  const createFilterParams = (data, defaultObj) => {
    let ignoreKeys = ["author_data", "admin_data", "date", "tags_data"];
    let filterParams = {};
    if (defaultObj) {
      filterParams = { ...filterParams, ...defaultObj };
    }
    genrageFilterParams(data, filterParams, ignoreKeys);
    return filterParams;
  };

  const getAdminProfilesCount = () => {
    let qryParams = createFilterParams(filterData);
    setIsLoading(true);
    ApiClient.getPatientCount(qryParams)
      .then((res) => {
        setCountData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getAdminProfiles = () => {
    let defaultObj = {
      status: status.link,
      page: currentPage,
      page_size: "20",
    };
    let qryParams = createFilterParams(filterData, defaultObj);

    setIsLoading(true);
    ApiClient.getPatients(qryParams)
      .then((res) => {
        setTotalPages(res.headers["total-pages"]);
        setData(res.data);
        setIsLoading(false);
        setHideEmpty(false);
      })
      .catch((err) => {
        setHideEmpty(false);
        setIsLoading(false);
        console.log(err);
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const headerData = [
    {
      title: "Add Patient",
      icon: addIconWhite,
      status: true,
      onClick: () => navigation("/patient/new"),
    },
  ];

  const leftSideData = [
    {
      title: "Apply Filter",
      className: "themeColor boldFont",
      showBtn: true,
      icon: filters,
    },
  ];

  const clearFilters = () => {
    setFilterData(new Object());
    setClearFilterData(!clearFiltersData);
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ["tag_ids", "tags_data", "category_ids", "author_ids"];

    if (checkObjectLength(filterData, ignoreKeys)) {
      return (
        <div className="mt-3">
          <FilterChips
            clearData={clearFilters}
            setData={setFilterData}
            data={filterData}
          />
        </div>
      );
    } else {
      if (Object.keys(filterData).length > 0) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);

  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={leftSideData} data={headerData} />;
  }, []);

  const renderPostHeader = useMemo(
    () => (
      <PostHeader
        postLinks={usersCount}
        onClickHandle={(data) => {
          setCurrentPage(1);
          setStatus(data);
        }}
      />
    ),
    [usersCount]
  );
  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder="Search a patient by name or mobile number"
        searchMethod="PATIENTSEARCH"
        label="full_name"
        searchId="Patients"
        renderPath="/patient/{source_id}/dashboard" //ShowPatientProfile
        imagePath="profile_url"
      />
    );
  }, []);

  const showPatient = () => (
    <div className="teamHeader stickyHeader">
      <div className="headerFiles">
        <AdminPanelHeader
          iconTitle="person_outline"
          title="Patient"
          searchBox={renderSearchBox}
        />
        {renderDashBoardHeader}
      </div>
      <div className="hrLine" />
      <div className="teamBody">
        {isShowAlert && (
          <div className="mt-3">
            <CommonAlert
              isShowAlert={isShowAlert}
              setIsShowAlert={setIsShowAlert}
            />
          </div>
        )}
        {renderPostHeader}
        {renderFilterChips}
      </div>
    </div>
  );

  const renderEmptyView = () => {
    return <EmptyView hide={isLoading || hideEmpty} title="No data found" />;
  };

  const showPatients = <ShowPatientList data={data} assignTeam />;

  const patientBody = () => {
    return (
      <>
        {showPatient()}
        <div className="usersList wrapperContent">
          {data?.length > 0 ? showPatients : renderEmptyView()}
          <div className="d-flex justify-content-end mb-4">
            <Pagination
              data={data}
              length={countData?.all_patients?.count}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </>
    );
  };

  const getFilterData = (data) => {
    setFilterData(data);
    setCurrentPage(1);
  };

  const showFilters = useCallback(() => {
    return (
      <FilterModal
        clearFiltersData={clearFiltersData}
        setClearFilterData={setClearFilterData}
        filter="patient"
        getData={getFilterData}
        data={filterData}
      />
    );
  }, [clearFiltersData, filterData]);

  return (
    <div className="wrapper">
      {/* {sideBar} */}
      {patientBody()}
      <Loader show={isLoading} />
      {showFilters()}
    </div>
  );
}

export default Patient;
