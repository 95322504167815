import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssignteamCard, BodyCard, CommonAlert, Loader, PatientProfile } from 'whealth-core-web/components';
import WeightCard from './WeightCard';
import PatientActivity from './PatientActivity';
import { setLocations, setPatientDetails } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatString, profileImage, subtractMonths } from 'whealth-core-web/components/Helper';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { ZohoCalendar } from 'whealth-core-web/components/ZohoCalendar/ZohoCalendar';
import MedicineActiveCard from './MedicineActiveCard';
import StatusCard from 'whealth-core-web/components/StatusCard';
import DateCards from 'whealth-core-web/components/DateCards';
import RenewalDateCard from 'whealth-core-web/components/RenewalDateCard';
import PatientPlanDetails from './Plans2Temp/PatientPlanDetails';
import { blue_calendar_clock } from 'res/images';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import CallManagementModal from 'whealth-core-web/components/patient/CallManagementModal';
import { convertToAmPm, formatDate } from 'whealth-core-web/components/Helper';

function ShowPatientProfile() {
  const [selectedMode, setSelectedMode] = useState('Personal Details'); // Personal Details, Plan Details
  const ApiClient = useApiManager();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { patientDetails, updatePatientDetails } = useSelector((state) => state.sliceReducer);
  const [businessConfig, setBusinessConfig] = useState({});
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showWeight, setWeight] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [dateValue, setDateValue] = useState(dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)));
  const [prescribedMedicne, setPrescribedMedicine] = useState('');
  const [callLogNotes, setCallLogNotes] = useState('');
  const [editStatus, setEditStatus] = useState(false);
  const [editOnboardStatus, setEditOnboardStatus] = useState(false);
  const [status, setStatus] = useState([]);
  const containerRef = useRef(null);
  const profileQuestionkeys = 'consultant_date,blood_test_date';
  const [callTypeName, setCallTypeName] = useState({});

  const getGraphData = () => {
    const queryDate = { date_range: dateValue };
    ApiClient.weightGraph(id, queryDate)
      .then((res) => {
        setGraphData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    prescribedMedicine();
  }, []);

  useEffect(() => {
    getPatientData();
  }, [id, updatePatientDetails]);

  useEffect(() => {
    const getBusinessConfig = async () => {
      const res = await ApiClient.getConfig();
      if (res.status == 200) setBusinessConfig(res.data.configuration);
    };
    getBusinessConfig();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEditStatus(false);
        setEditOnboardStatus(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
  }, []);

  useEffect(() => {
    if (status?.length == 0) {
      getPatientStatus();
    }
  }, []);

  const getPatientStatus = () => {
    const type = { type: 'patient' };
    ApiClient.allStatus(type)
      .then((res) => {
        setStatus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const prescribedMedicine = () => {
    setIsLoading(true);
    ApiClient.prescribedMedicine(id)
      .then((res) => {
        setIsLoading(false);
        setPrescribedMedicine(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getPatientData = useCallback(() => {
    setIsLoading(true);
    prescribedMedicine();
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setIsLoading(false);
        let newuserData = { ...res.data };
        newuserData.status_uuid = res.data.status.uuid;
        let tempLocations = [];
        newuserData?.locations?.map((item) => {
          tempLocations.push(item.id);
        });
        // newuserData?.tempLocations = tempLocations
        const reformattedData = { ...newuserData, locations: tempLocations };
        setData(reformattedData);
        dispatch(setPatientDetails(reformattedData));
        const userDetails = {
          name: newuserData.full_name,
          gender: newuserData.gender,
          image: newuserData.profile_url,
        };
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [id, updatePatientDetails]);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.getLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      title: patientDetails.full_name,
      icon: profileImage(patientDetails.gender, patientDetails.profile_url),
      link: '',
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'scheduled':
        return '#333333';
      case 'cancelled':
        return '#DC2626';
      case 'no_show':
        return '#DC2626';
      case 'rescheduled':
        return '#333333';
      case 'initiated':
        return '#FFE897';
      case 'completed':
        return '#243B86';
    }
  };

  const getTitle = (key) => {
    switch (key) {
      case 'hc_call':
        return 'HC Call';
      case 'onboarding_call':
        return 'Onboarding Call';
      default:
        return '';
    }
  };

  const dateTimeStatus = (status, date, time) => {
    const color = getStatusColor(status);

    const containerStyle = {
      padding: 5,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 15,
      backgroundColor: color,
    };

    const textStyle = {
      fontWeight: 500,
      fontSize: 12,
    };

    if (status === 'initiated' || status === 'cancelled') {
      return (
        <div style={containerStyle}>
          <p style={{ ...textStyle, color: status === 'initiated' ? '#945900' : 'white' }}>
            {status === 'initiated' ? 'Pending' : 'Cancelled'}
          </p>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ fontWeight: 500, fontSize: 14, color }}>{formatDate(date)}</p>
          <div style={{ backgroundColor: color, width: 1, margin: '0 5px', height: 16 }}></div>
          <p style={{ fontWeight: 500, fontSize: 14, color }}>{convertToAmPm(time)}</p>
        </div>
      );
    }
  };

  const renderDynamicTiles = () => {
    const callStatus = data?.call_status || {};
    return (
      <>
        <BodyCard data={data} getPatientData={getPatientData} />
        <AssignteamCard
          getPatientData={getPatientData}
          searchRole={'doctor,health_coach,health_coach_lead,admin,diet_health_coach,fitness_health_coach'}
          userData={data}
          inputValue={inputValue}
          placeholder={'Assign Doctor/Health Coach'}
          setInputValue={setInputValue}
        />
        <div ref={containerRef} className='card memberShipDetails w-40 mb-4  no-border' style={{ minWidth: '100%' }}>
          <div className='card-body memberShipcard-body pb-0'>
            <StatusCard
              patientUpdate
              setUpdateStatus={setEditStatus}
              onboardingStatus
              updateStatus={editStatus}
              status={status}
              setStatus={setStatus}
              getPatientData={getPatientData}
              badgeTitle={data?.status?.title}
            />
            <div className='memberinfo-list mt-3'>
              {data?.profile_info?.map((item, index) => {
                if (profileQuestionkeys.includes(item.key)) {
                  return (
                    <DateCards
                      data={item}
                      title={item.question}
                      value={item.answer}
                      index={index}
                      getPatientData={getPatientData}
                      isTimeShow={item.type == 'date_time'}
                      isEditable
                      calendarAlign={'auto'}
                    />
                  );
                }
              })}
              <RenewalDateCard data={data} title='Renewal On' />
              {data?.subscription_title && (
                <DateCards title={'Subscription'} value={data?.subscription_title} calendarAlign={'auto'} />
              )}
            </div>
          </div>
        </div>

        <div
          ref={containerRef}
          className='card memberShipDetails w-40 mb-4  no-border'
          style={{ minWidth: '100%', paddingRight: 15 }}
        >
          <div className='card-body memberShipcard-body pb-0'>
            {Object.keys(callStatus).length > 0 &&
              Object.entries(callStatus).map((item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderBottom: '1px dashed #D9D9D9',
                      marginBottom: 10,
                      paddingBottom: 5,
                    }}
                  >
                    <p style={{ color: '#787C80', fontWeight: 400, fontSize: 14 }}>
                      {getTitle(item[0])}{' '}
                      {item[0] === 'hc_call' && item[1].type.split('_')[item[1].type.split('_').length - 1]}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {dateTimeStatus(item[1]?.status, item[1]?.call_date, item[1]?.time)}

                      <div
                        style={{
                          backgroundColor: '#D1DCFF',
                          padding: 6,
                          borderRadius: 7,
                          marginLeft: 10,
                        }}
                        data-bs-toggle={
                          item[1]?.call_date === null ||
                          item[1]?.status === 'completed' ||
                          item[1]?.status === 'cancelled'
                            ? undefined
                            : 'offcanvas'
                        }
                        data-bs-target={
                          item[1]?.call_date === null ||
                          item[1]?.status === 'completed' ||
                          item[1]?.status === 'cancelled'
                            ? undefined
                            : '#callModal'
                        }
                        onClick={
                          item[1]?.call_date === null ||
                          item[1]?.status === 'completed' ||
                          item[1]?.status === 'cancelled'
                            ? null
                            : () => {
                                setCallTypeName({
                                  callType: item[0],
                                  ...item[1],
                                });
                              }
                        }
                      >
                        <CommonToolTip
                          placement={'top'}
                          item={<img src={blue_calendar_clock} style={{ height: 20, width: 20 }} />}
                          showValue={'Status'}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <MedicineActiveCard medicineData={prescribedMedicne} />
      </>
    );
  };

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a patient by name or mobile number'
        searchMethod='PATIENTSEARCH'
        label='full_name'
        searchId='Patients'
        renderPath='/patient/{source_id}/dashboard' //ShowPatientProfile
        imagePath='profile_url'
      />
    );
  }, []);

  const renderAdminPanelHeader = useMemo(() => {
    return (
      <AdminPanelHeader
        searchBox={renderSearchBox}
        iconTitle='person_outline'
        showTree={showTree}
        title='Patient'
        placeholder='Search Patients'
      />
    );
  }, [patientDetails]);

  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={[]} />;
  }, []);

  const showPatient = () => (
    <>
      <div className='patientHeader stickyHeader boxShadow'>
        {renderAdminPanelHeader}
        {renderDashBoardHeader}
      </div>
    </>
  );
  const showPersonalDetails = () => (
    <div className='p-3 wrapperContent'>
      <CommonAlert className='m-2' redux='patient' />
      <div className='d-md-flex flex-wrap patientTabContent'>
        <div className={'w-65'}>
          <PatientProfile setCallLogNotes={setCallLogNotes} data={data} getPatientData={getPatientData} />
          <PatientActivity
            activeNotesCallLog={callLogNotes}
            setCallLogNotes={setCallLogNotes}
            getPatientData={getPatientData}
            getGraphData={getGraphData}
            data={data}
          />
        </div>

        <div className='p-2 w-35'>
          {renderDynamicTiles()}
          {businessConfig?.enable_zoho_meeting && !data?.is_zoho_token_expired && (
            <div className='mt-4 patient-zoho-calendar'>
              <ZohoCalendar showBookings={true} isPatientView={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const showPlanDetails = () => (
    <div className='p-3 wrapperContent '>
      <PatientPlanDetails patientId={id} />
    </div>
  );
  const renderModeSwitcher = () => {
    const personalDetailsIsSelected = selectedMode === 'Personal Details';
    const styles = {
      container: (isSelected) => ({
        border: '1px solid #C8D2ED',
        backgroundColor: isSelected ? '#C8D2ED' : 'transparent',
        color: '#243B86',
        borderRadius: '8px', // Adjust the radius as needed
        padding: '8px 16px', // Horizontal padding (left and right)
      }),
    };
    return (
      <div className='px-3 pt-3 wrapperContent'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            padding: '10px',
            cursor: 'pointer',
          }}
        >
          <div
            onClick={() => {
              setSelectedMode('Personal Details');
            }}
            style={styles.container(personalDetailsIsSelected)}
          >
            Personal Details
          </div>
          <div
            style={styles.container(!personalDetailsIsSelected)}
            onClick={() => {
              setSelectedMode('Plan Details');
            }}
            className={personalDetailsIsSelected ? '' : 'active'}
          >
            Plan Details
          </div>
        </div>
      </div>
    );
  };
  const patientBody = () => {
    return (
      <>
        {showPatient()}
        {renderModeSwitcher()}
        {selectedMode === 'Personal Details' && showPersonalDetails()}
        {selectedMode === 'Plan Details' && showPlanDetails()}
      </>
    );
  };

  return (
    <div onClick={() => setInputValue('')} className='patient-body wrapper'>
      {patientBody()}
      <CallManagementModal
        patientId={data?.id}
        callTypeName={callTypeName}
        getPatientData={getPatientData}
        setIsLoading={setIsLoading}
      />

      <Loader show={isLoading} />
      {/* {showFilters()} */}
    </div>
  );
}

export default ShowPatientProfile;
