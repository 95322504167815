import React, { useState } from "react";
import { ChevronDown, CirclePause, ClockArrowUp } from "lucide-react";
import ExpandedPlanDetails from "./ExpandedPlanDetails";
import { getColor } from "./planUtils";
import PausePlanDrawer from "whealth-core-web/components/PausePlanDrawer";
import ExtendPlanDrawer from "whealth-core-web/components/ExtendPlanDrawer";

const PlanTile = ({
  planData,
  showPauseAndExtension = false,
  patientId,
  setInvalidateAPIKey,
}) => {
  const status = planData?.status;
  const planType = planData.plan.type;
  const [openPausePlanDrawer, setOpenPausePlanDrawer] = useState(false);
  const [openExtendPlanDrawer, setOpenExtendPlanDrawer] = useState(false);

  const [expanded, setExpanded] = useState(false);
  if (!planData) {
    return;
  }
  const { bgColor, textColor } = getColor(status);
  const [startDate, endDate] = [planData.start_date, planData.end_date];
  const planName = planData?.plan.name;
  const planDuration = planData.plan.plan_duration;
  const paymentDetails = planData?.payment;
  const planPrice = paymentDetails?.amount;
  const paymentMode = paymentDetails?.payment_mode;
  const paymentId = paymentDetails?.payment_details?.id;
  const pauseDuration = planData?.pause_duration;
  const extensionDuration = planData?.extension_duration;
  const noOfPauses = planData?.user_plan_pause?.length;
  const noOfExtension = planData?.user_plan_extension?.length;
  const toggleExpand = () => {
    setExpanded((p) => !p);
  };

  const togglePausePlanDrawer = () => {
    setOpenPausePlanDrawer((p) => !p);
  };

  const toggleExtendPlanDrawer = () => {
    setOpenExtendPlanDrawer((p) => !p);
  };
  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#F1F5FF",
        fontSize: "0.75rem",
        width: "max-content",
        gap: "0.25rem",
        paddingInline: "0.5rem",
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
        borderTopLeftRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
        borderBottomRightRadius: "0.5rem",
        fontWeight: 500,
      }}
    >
      <p
        className="capitalize"
        style={{
          backgroundColor: bgColor,
          color: textColor,
          padding: "0.25rem",
          paddingInline: "0.5rem",
          borderRadius: "0.5rem",
        }}
      >
        {status}
      </p>
      <span style={{ display: "flex", alignItems: "center", gap: "0.10rem" }}>
        <span style={{ color: "#333333" }}>{startDate}</span>
        <span>-</span>
        <span style={{ color: "#333333" }}>{endDate}</span>
      </span>
    </div>
  );

  const verticalSeparator = (
    <div
      style={{
        border: "1px solid #243b86",
        height: "20px",
        width: "0.8px",
        marginTop: "0.4rem",
        marginBottom: "0.4rem",
      }}
    />
  );
  const body = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "0.5rem",
        color: "#243b86",
        fontSize: "0.9rem",
        fontWeight: 600,
      }}
    >
      <div>{planName}</div>
      {verticalSeparator}
      <div className="capitalize">{planType}</div>
      {verticalSeparator}
      <div>{planDuration} Days</div>
    </div>
  );

  const footer = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "0.5rem",
        backgroundColor: "#F1F5FF",
        padding: "0.25rem",
        paddingInline: "0.5rem",
        fontSize: "0.9rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "0.5rem",
          color: "#333333",
        }}
      >
        <div>
          {paymentMode === "cash"
            ? `Plan Price: Rs. ${planPrice} |
          Mode: ${paymentMode} `
            : `Plan Price: Rs. ${planPrice} |
          Payment ID: ${paymentId} |
          Mode: ${paymentMode}`}
        </div>
      </div>
      <button
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "transparent",
          gap: "0.25rem",
        }}
        onClick={toggleExpand}
      >
        <span>View Details</span>
        <ChevronDown
          style={{
            color: "#243b86",
            backgroundColor: "#D1DCFF",
            borderRadius: "0.25rem",
            padding: "0.1rem",
          }}
          size="0.95rem"
        />
      </button>
    </div>
  );
  const pauseAndExtension = (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        position: "absolute",
        right: "1rem",
        top: "0.5rem",
        fontSize: "1rem",
        padding: "0.5rem",
        borderRadius: "0.5rem",
      }}
    >
      <button
        style={{
          backgroundColor: "#D1DCFF",
          color: "#243B86",
          borderRadius: "0.25rem",
          padding: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
        }}
        onClick={togglePausePlanDrawer}
      >
        <CirclePause size={"1rem"} />
        Pause
      </button>
      <button
        style={{
          backgroundColor: "#D1DCFF",
          color: "#243B86",
          borderRadius: "0.25rem",
          padding: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
        }}
        onClick={toggleExtendPlanDrawer}
      >
        <ClockArrowUp size={"1rem"} />
        Extend
      </button>
    </div>
  );
  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #E1E1E1",
        borderRadius: ".5rem",
        // width: "100%",
        backgroundColor: "#fff",
        overflow: "hidden",
      }}
    >
      <div style={{}}>{header}</div>
      {showPauseAndExtension && pauseAndExtension}
      <div style={{ padding: "0.5rem" }}>
        {body}
        <div style={{ color: "#333" }}>
          <div>
            {noOfPauses} pause ({pauseDuration} Days) | {noOfExtension}{" "}
            extention ({extensionDuration} Days)
          </div>
        </div>
      </div>
      {footer}
      {expanded && <ExpandedPlanDetails planDetails={planData} />}
      <PausePlanDrawer
        planData={planData}
        isOpen={openPausePlanDrawer}
        setIsOpen={setOpenPausePlanDrawer}
        patientId={patientId}
        setInvalidateAPIKey={setInvalidateAPIKey}
      />
      <ExtendPlanDrawer
        isOpen={openExtendPlanDrawer}
        setIsOpen={setOpenExtendPlanDrawer}
        planData={planData}
        patientId={patientId}
        setInvalidateAPIKey={setInvalidateAPIKey}
      />
    </div>
  );
};

export default PlanTile;

export const dummyPlanData = {
  id: 7,
  user: 4534,
  amount_paid: "300.00",
  buy_date: "2024-10-29",
  start_date: "2024-10-31",
  end_date: "2025-04-20",
  pause_duration: 130,
  extension_duration: 10,
  total_pause_count: 13,
  total_extension_count: 1,
  active: false,
  renewal: false,
  plan: {
    id: 2,
    name: "1 Month Plan",
  },
  user_plan_items: [
    {
      id: 11,
      product: {
        id: 1453,
        product_name: "cgm",
      },
      quantity: 3,
    },
    {
      id: 12,
      product: {
        id: 1454,
        product_name: "ring",
      },
      quantity: 3,
    },
  ],
  user_plan_pause: [
    {
      id: 6,
      user_plan: 7,
      type: "pause",
      duration: 10,
      start_date: "2024-11-05",
      created_by: null,
      updated_by: null,
      updated_at: "2024-11-05T19:23:09.147479Z",
      created_at: "2024-11-05T19:23:09.147223Z",
    },

    {
      id: 15,
      user_plan: 7,
      type: "pause",
      duration: 10,
      start_date: "2024-12-07",
      created_by: null,
      updated_by: null,
      updated_at: "2024-11-05T19:54:01.436738Z",
      created_at: "2024-11-05T19:54:01.436619Z",
    },
    {
      id: 16,
      user_plan: 7,
      type: "pause",
      duration: 10,
      start_date: "2024-12-07",
      created_by: {
        id: 31,
        full_name: "Ravi Ranjan",
      },
      updated_by: {
        id: 31,
        full_name: "Ravi Ranjan",
      },
      updated_at: "2024-11-05T19:56:42.056676Z",
      created_at: "2024-11-05T19:56:42.056454Z",
    },
  ],
  user_plan_extension: [
    {
      id: 2,
      user_plan: 7,
      type: "extension",
      duration: 10,
      start_date: "2024-11-05",
      created_by: null,
      updated_by: null,
      updated_at: "2024-10-31T09:45:57.788586Z",
      created_at: "2024-10-31T09:45:57.788562Z",
    },
    {
      id: 3,
      user_plan: 7,
      type: "extension",
      duration: 10,
      start_date: "2024-11-05",
      created_by: null,
      updated_by: null,
      updated_at: "2024-10-31T09:48:56.322206Z",
      created_at: "2024-10-31T09:48:56.322183Z",
    },
  ],
  updated_at: "2024-11-05T19:56:42.074731Z",
  created_at: "2024-10-29T07:59:18.442194Z",
};
