import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import Pagination from '../Pagination';
import CommonBorderBox from '../CommonBorderBox';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';
import DietTemplateForm from './DietTemplateForm';
import DietTemplateList from './DietTemplateList';

function DietTemplate(props) {
  const ApiClient = useApiManager();
  const { setIsLoading, setIsShowAlert, setAlertType } = props;
  const [isEditMode, setEditMode] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [dietTemplate, setDietTemplate] = useState({
    diet_plans: [
      {
        day_part_id: '',
        notification_time: '',
        diet_plan_food_items: [
          {
            food: '',
            quantity: '',
            recipe_link: '',
            twins: '1',
          },
        ],
      },
    ],
  });
  const [editDietTemplate, setEditDietTemplate] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [regimeTemplateList, setRegimeTemplateList] = useState([]);
  const [searchRegime, setSearchRegime] = useState('');
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const [updateAlertType, setIsUpdateAlertType] = useState('alert-success');

  useEffect(() => {
    let params = { page: 1 };
    ApiClient.getDietTemplate(params).then((res) => {
      setQuestionsList(res.data);
    });
  }, []);

  useEffect(() => {
    if (searchRegime?.length > 2) {
      searchDietTemplate(searchRegime, currentPage);
    } else {
      getDietTemplatesList();
    }
  }, [currentPage]);

  const searchDietTemplate = (searchData, pageNo) => {
    setIsLoading(true);
    setSearchRegime(searchData);
    if (searchData?.length > 2) {
      ApiClient.searchDietTemplateList({ search_str: searchData, page: pageNo || 1 })
        .then((res) => {
          setTotalPages(res.headers['total-pages']);
          setRegimeTemplateList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      getDietTemplatesList();
    }
  };

  const getDietTemplatesList = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getDietTemplateList(params)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setRegimeTemplateList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const deleteDietTemplate = (id) => {
    setIsLoading(true);
    ApiClient.deleteDietTemplate(id)
      .then((res) => {
        setIsLoading(false);
        getDietTemplatesList();
        setAlertType('alert-success');
        setIsShowAlert('Diet Template Deleted Successfully');
      })
      .catch((err) => {
        console.warn('fdf', err);
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.error);
      });
  };

  const getDietTemplate = (item) => {
    setIsLoading(true);
    ApiClient.getDietTemplate(item.id).then((res) => {
      setEditMode(true);
      let updateDietData = res.data;
      setEditDietTemplate({
        id: updateDietData.id,
        title: updateDietData.title,
        guidelines: updateDietData.guidelines,
        default: updateDietData.default,
        diet_plans: updateDietData.diet_plan_day_parts.map((dayPart) => {
          return {
            day_part_id: dayPart.day_part.id,
            notification_time: dayPart.notification_time,
            recipe_link: dayPart.recipe_link,
            rank: dayPart.rank,
            diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => {
              return {
                food: foodItem.food,
                quantity: foodItem.quantity,
                recipe_link: foodItem.recipe_link,
                twins: foodItem.twins,
              };
            }),
          };
        }),
      });
      setIsLoading(false);
    });
  };

  return (
    <div className='mb-3'>
      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>Add New Diet Plan</div>
        <DietTemplateForm
          questionsList={questionsList}
          dietTemplate={dietTemplate}
          setDietTemplate={setDietTemplate}
          getDietTemplatesList={getDietTemplatesList}
          setIsShowAlert={setIsShowAlert}
          setAlertType={setAlertType}
          setQuestionsList={setQuestionsList}
        />
      </CommonBorderBox>
      <div className='table-responsive'>
        <DietTemplateList
          regimeTemplateList={regimeTemplateList}
          editDietTemplate={getDietTemplate}
          deleteDietTemplate={deleteDietTemplate}
          searchDietTemplate={searchDietTemplate}
        />
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <Modal
        backdrop='static'
        size='md'
        open={isEditMode}
        onClose={() => {
          setEditMode(false);
        }}
      >
        <Modal.Header>
          <div className='settingFormOutline p-0'>
            <div className='formTitle mb-0'>Update Diet Templates</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CommonAlert
            isShowAlert={isUpdateAlert}
            alertType={updateAlertType}
            setIsShowAlert={setIsUpdateAlert}
            className='mt-0 mb-3'
          />

          <DietTemplateForm
            key={dietTemplate.id}
            questionsList={questionsList}
            dietTemplate={editDietTemplate}
            setDietTemplate={setEditDietTemplate}
            getDietTemplatesList={getDietTemplatesList}
            setIsShowAlert={setIsShowAlert}
            setAlertType={setAlertType}
            isEditMode
            setEditMode={setEditMode}
            setQuestionsList={setQuestionsList}
            setIsUpdateAlert={setIsUpdateAlert}
            setIsUpdateAlertType={setIsUpdateAlertType}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DietTemplate;
